import { FACET_CS_DEV_KEY } from '@/components/SearchResultsFacets/constants';
import { ContentType } from '@/constants/contentStack';
import { CONTENT_STACK_DEV_KEY } from '@/constants/index';
import { findByEntryId } from '@/services/content/business.lib';
import { getConfigurationSettingByDeveloperKey } from '@/services/content/business.lib';
import { getConfigurationSettingByDevelopmentKey } from '@/services/content/config.lib';
import type {
    AdBuilderReference,
    AdSetCostcoReference,
    AdSetThirdPartyReference,
    AdTargetPlacementBlockReference,
    BulletDetailReference,
    ButtonSetReference,
    CategoryLandingPage,
    ComposerData,
    ContentGroup,
    ContentStackEntryDataProps,
    CriteoInGridBlockReference,
    FeatureHighlightCardV2Reference,
    LocaleProps,
    MarkdownReference,
    ProgramCardReference,
    SearchComposerData,
    SearchComposerV2Data,
    SearchPageConfigProperties,
    SearchRuleEntry,
    SearchServiceConfig,
} from '@/types/contentStack';

import {
    FIRST_ITEM_INDEX_TOKEN,
    LAST_ITEM_INDEX_TOKEN,
    TOTAL_ITEMS_TOKEN,
} from './constants';

export function isAdBuilderEntry(
    entry: ContentGroup
): entry is AdBuilderReference {
    return 'ad_builder_block' in entry;
}

export function isAdSetCostcoEntry(
    entry: ContentGroup
): entry is AdSetCostcoReference {
    return 'ad_set_costco_block' in entry;
}

export function isAdSetThirdPartyEntry(
    entry: ContentGroup
): entry is AdSetThirdPartyReference {
    return 'ad_set_3rd_party_block' in entry;
}

export function isCriteoInGridBannerEntry(
    entry: ContentGroup
): entry is CriteoInGridBlockReference {
    return 'criteo_ingrid_ad_block' in entry;
}

export function isCustomRichTextBlock(entry: ContentGroup) {
    return 'custom_rich_text_block' in entry;
}

export function isButtonEntry(
    entry: ContentGroup
): entry is ButtonSetReference {
    return 'button_set_block' in entry;
}

export function isMarkdownEntry(
    entry: ContentGroup
): entry is MarkdownReference {
    return 'markdown_block' in entry;
}

export function isBulletDetailEntry(
    entry: ContentGroup
): entry is BulletDetailReference {
    return 'bullet_detail_card_block' in entry;
}

export function isProgramCardEntry(
    entry: ContentGroup
): entry is ProgramCardReference {
    return 'program_card_block' in entry;
}

export function isFeatureHighlightCardV2Entry(
    entry: ContentGroup
): entry is FeatureHighlightCardV2Reference {
    return 'feature_highlight_card_v2_block' in entry;
}

export function getDisplayGroupId(entry: ContentGroup) {
    if (isAdBuilderEntry(entry)) {
        return entry.ad_builder_block.ad_builder_ref?.[0]?.uid;
    }
    if (isAdSetCostcoEntry(entry)) {
        return entry.ad_set_costco_block.ad_set_costco_ref?.[0]?.uid;
    }
    if (isAdSetThirdPartyEntry(entry)) {
        return entry.ad_set_3rd_party_block.ad_set_3rd_party_ref?.[0]?.uid;
    }
    if (isCriteoInGridBannerEntry(entry)) {
        return entry.criteo_ingrid_ad_block?.ingrid_type_ref;
    }
    if (isBulletDetailEntry(entry)) {
        return entry.bullet_detail_card_block.bullet_detail_card_ref?.[0]?.uid;
    }
    if (isButtonEntry(entry)) {
        return entry.button_set_block.button_set_ref?.[0]?.uid;
    }
    if (isCustomRichTextBlock(entry)) {
        return entry.custom_rich_text_block.custom_rich_text_ref?.[0]?.uid;
    }
    if (isFeatureHighlightCardV2Entry(entry)) {
        return entry.feature_highlight_card_v2_block
            .feature_highlight_card_v2_ref?.[0]?.uid;
    }
    if (isMarkdownEntry(entry)) {
        return entry.markdown_block._metadata?.uid;
    }
    if (isProgramCardEntry(entry)) {
        return entry.program_card_block.program_card_ref?.[0]?.uid;
    }
}

export function getDisplayGroupContentType(entry: AdSetThirdPartyReference) {
    if (isAdSetThirdPartyEntry(entry)) {
        return entry.ad_set_3rd_party_block?.ad_set_3rd_party_ref?.[0]
            ?._content_type_uid;
    }
}

export function populateProductCount(
    template: string | undefined,
    data: {
        firstDisplayedItemIndex: number;
        lastDisplayedItemIndex: number;
        totalItems: number;
    }
) {
    if (!template) {
        return null;
    }
    return template
        .replace(
            FIRST_ITEM_INDEX_TOKEN,
            data.firstDisplayedItemIndex.toLocaleString()
        )
        .replace(
            LAST_ITEM_INDEX_TOKEN,
            data.lastDisplayedItemIndex.toLocaleString()
        )
        .replace(TOTAL_ITEMS_TOKEN, data.totalItems.toLocaleString());
}

export function getFirstItemIndex(currentPage: number, resultsPerPage: number) {
    return currentPage * resultsPerPage - resultsPerPage + 1;
}

export function getLastItemIndex(
    currentPage: number,
    resultsPerPage: number,
    totalDocs: number
) {
    let lastDisplayedItemIndex = currentPage * resultsPerPage;

    if (lastDisplayedItemIndex > totalDocs) {
        lastDisplayedItemIndex = totalDocs;
    }

    return lastDisplayedItemIndex;
}

export function getComposerDataFromEntry(
    composerData?:
        | SearchComposerData
        | SearchComposerV2Data
        | CategoryLandingPage,
    ruleSpecificComposerData?: ComposerData
): ComposerData | undefined {
    let hasAboveGridPlacementFoundFirstTargetingPlacement = false;
    let belowGridPlacementFoundFirstTargetingPlacement = false;
    let inGridPlacementFoundFirstTargetingPlacement = false;

    let compiledComposerData = {
        aboveGridPlacements: [],
        belowGridPlacements: [],
        inGridPlacements: [],
    } as ComposerData;

    // Support for v1 and v2 SearchComposer as well as CLP (same as V2)
    let aboveSearch: ContentGroup[] = [];
    if (Array.isArray(composerData?.above_search_results)) {
        aboveSearch = composerData?.above_search_results;
    } else if (
        composerData?.above_search_results.hasOwnProperty('ad_placements')
    ) {
        aboveSearch = composerData?.above_search_results?.ad_placements;
    }

    aboveSearch?.forEach(adPlacement => {
        if (
            (adPlacement as AdTargetPlacementBlockReference)
                .ad_targeting_placement_block?.enable_targeted_ads
        ) {
            if (
                !hasAboveGridPlacementFoundFirstTargetingPlacement &&
                ruleSpecificComposerData &&
                ruleSpecificComposerData?.aboveGridPlacements?.length > 0
            ) {
                compiledComposerData.aboveGridPlacements.push(
                    ...ruleSpecificComposerData?.aboveGridPlacements
                );
                hasAboveGridPlacementFoundFirstTargetingPlacement = true;
            }
        } else {
            compiledComposerData.aboveGridPlacements.push(adPlacement);
        }
    });

    let belowSearch: ContentGroup[] = [];

    if (Array.isArray(composerData?.below_search_results)) {
        belowSearch = composerData?.below_search_results;
    } else if (
        composerData?.below_search_results.hasOwnProperty('ad_placements')
    ) {
        belowSearch = composerData?.below_search_results?.ad_placements;
    }

    belowSearch?.forEach(adPlacement => {
        if (
            (adPlacement as AdTargetPlacementBlockReference)
                .ad_targeting_placement_block?.enable_targeted_ads
        ) {
            if (
                !belowGridPlacementFoundFirstTargetingPlacement &&
                ruleSpecificComposerData &&
                ruleSpecificComposerData?.belowGridPlacements?.length > 0
            ) {
                compiledComposerData.belowGridPlacements.push(
                    ...ruleSpecificComposerData?.belowGridPlacements
                );
                belowGridPlacementFoundFirstTargetingPlacement = true;
            }
        } else {
            compiledComposerData.belowGridPlacements.push(adPlacement);
        }
    });

    let inGridSearchResults: ContentGroup[] = [];

    if (Array.isArray(composerData?.ingrid_search_results)) {
        inGridSearchResults = composerData?.ingrid_search_results;
    } else if (
        composerData?.ingrid_search_results.hasOwnProperty('ad_placements')
    ) {
        inGridSearchResults =
            composerData?.ingrid_search_results?.ad_placements;
    }

    inGridSearchResults?.forEach(adPlacement => {
        if (
            (adPlacement as AdTargetPlacementBlockReference)
                .ad_targeting_placement_block?.enable_targeted_ads
        ) {
            if (
                !inGridPlacementFoundFirstTargetingPlacement &&
                ruleSpecificComposerData &&
                ruleSpecificComposerData?.inGridPlacements?.length > 0
            ) {
                compiledComposerData.inGridPlacements.push(
                    ...ruleSpecificComposerData?.inGridPlacements
                );
                inGridPlacementFoundFirstTargetingPlacement = true;
            }
        } else {
            compiledComposerData.inGridPlacements.push(adPlacement);
        }
    });

    return compiledComposerData;
}

function _createCriteoSearchFilterString(
    refine: string,
    regx: RegExp,
    filterContent: string,
    {
        modifyMatch,
        modifyFilters,
    }: {
        modifyMatch?: (s?: string) => string | undefined;
        modifyFilters?: (s?: string[]) => string[];
    } = {}
) {
    let matches = [...refine.matchAll(regx)];

    let filters = matches.reduce((filters, match) => {
        let matchingValue = match?.[1];

        if (modifyMatch) {
            matchingValue = modifyMatch(matchingValue);
        }

        if (matchingValue) {
            filters.push(matchingValue);
        }

        return filters;
    }, [] as string[]);

    if (modifyFilters) {
        filters = modifyFilters(filters);
    }

    const filterString =
        filters.length > 0
            ? `(${filterContent}${filters.join(',')})`
            : undefined;

    return filterString;
}

export function createCriteoSearchFiltersString(
    refine: string | string[] = ''
    // facets: Facet[]
) {
    if (Array.isArray(refine)) {
        refine = refine[0] as string;
    }
    let ratingFilterString = _createCriteoSearchFilterString(
        refine,
        /item_rating_value-([0-9])\s*&*\s*[a-zA-Z]*/gi,
        'ratings,ge,',
        {
            modifyFilters: (matchingValues?: string[]) => {
                if (!matchingValues?.length) {
                    return [];
                }

                return [
                    matchingValues?.reduce((smallestValue, value) => {
                        if (
                            !smallestValue ||
                            parseInt(value) < parseInt(smallestValue)
                        ) {
                            return value;
                        }

                        return smallestValue;
                    }),
                ];
            },
        }
    );

    let priceFilterString = _createCriteoSearchFilterString(
        refine,
        /item_location_pricing_salePrice-\[([0-9]+\s*TO\s*[0-9]+)\]/gi,
        'price_range,in,',
        {
            modifyMatch: (matchingValue?: string) => {
                return matchingValue?.replace(/\s*TO\s*/, '-');
            },
        }
    );

    let brandFilterString = _createCriteoSearchFilterString(
        refine,
        /Brand_attr-((?:(?!\|\|).)+)(\|\||$)/gi,
        'brand,in,'
    );

    return [ratingFilterString, priceFilterString, brandFilterString]
        .filter(s => !!s)
        .join(', ');
}

export async function getCLPComposerDataFromEntry(
    entryId: string,
    lang: LocaleProps
): Promise<ComposerData | undefined> {
    const categoryLandingPageEntry = await findByEntryId<
        CategoryLandingPage | undefined
    >(lang, ContentType.CategoryLandingPage, entryId);

    return getComposerDataFromEntry(categoryLandingPageEntry);
}

export async function getServerSideConfig({
    lang,
    enableRuleLivePreview,
}: {
    lang: LocaleProps;
    enableRuleLivePreview?: boolean;
}) {
    const config = await getConfigurationSettingByDeveloperKey<
        ContentStackEntryDataProps<SearchPageConfigProperties>
    >(ContentType.PageSettings, CONTENT_STACK_DEV_KEY, lang);
    const facetConfig =
        await getConfigurationSettingByDeveloperKey<ContentStackEntryDataProps>(
            ContentType.ModuleSettings,
            FACET_CS_DEV_KEY,
            lang,
            enableRuleLivePreview
        );

    const serviceConfig =
        await getConfigurationSettingByDevelopmentKey<SearchServiceConfig>(
            ContentType.ServiceConfiguration,
            config.properties[0]!.configKey
        );

    return { config, facetConfig, serviceConfig };
}

// Category landing pages could have search enabled or not
export function isSearchEnabled(
    categoryLandingPage: CategoryLandingPage | SearchRuleEntry | undefined
): boolean {
    if (!categoryLandingPage) {
        return true;
    }

    if ('enable_search' in categoryLandingPage) {
        return categoryLandingPage.enable_search;
    }
    return true;
}

// Category landing pages could have search filters (left column) enabled or not
export function isFilterEnabled(
    categoryLandingPage: CategoryLandingPage | SearchRuleEntry | undefined
): boolean {
    if (!categoryLandingPage) {
        return true;
    }

    if ('enable_search_filter' in categoryLandingPage) {
        //  Enable Search is a parent of search filter, so if that's disabled, search filter is disabled
        if (categoryLandingPage.enable_search === false) {
            return false;
        }

        return categoryLandingPage.enable_search_filter;
    }

    return true;
}
