'use client';

import SearchPageDigitalDataLayer from '../Analytics/SearchPageDigitalDataLayer';
import { AnalyticsObserver } from '../AnalyticsObserver';
import { useCriteoAnalytics } from '../AnalyticsObserver/utils';
import { Variants } from 'node_modules/@costcolabs/forge-digital-components/dist/components/Tiles/ProductTile/constants';

import React, { Children, ReactNode, useMemo } from 'react';

import { SEARCH_RESULT_CONTAINER_ID } from '@/constants/index';
import {
    COMPONENT_TYPE_UI,
    KEY_SEARCH_INDEX,
    SEARCH,
    TRACK_TYPE_MULTI_ATTR,
    TRACK_TYPE_NAVIGATION,
    TRAVEL_DESTINATION,
    TRAVEL_ITEM_PID,
} from '@/src/components/Analytics/constants';
import { product_display_style } from '@/src/containers/search/constants';
import { useLucidWorksContext } from '@/src/services/search/LucidWorksProvider';
import {
    ComposerData,
    LocaleProps,
    SearchConfig,
} from '@/src/types/contentStack';
// import { SearchParams } from '@/src/types/searchQuery';
import { Grid } from '@costcolabs/forge-components';
import { ColorGray500 } from '@costcolabs/forge-design-tokens';
import {
    Analytics,
    CriteoButterflyDisplay,
    CriteoProductTile,
    ProductTileSkeleton,
    ProductTileUI, // useBrowseContext,
    useCheckScreen,
    useCriteo,
} from '@costcolabs/forge-digital-components';
import { Box } from '@mui/system';

type BeaconType = 'OnClickBeacon' | 'OnLoadBeacon' | 'OnViewBeacon';

type CriteoProduct = Record<BeaconType, string> & {
    ProductId: string;
    ParentSKU: string;
};

type ProductArray = CriteoProduct[];

type CriteoRendering = Record<string, string>;

type Placement = {
    format: string;
    OnClickBeacon: string;
    OnLoadBeacon: string;
    OnViewBeacon: string;
    rendering: CriteoRendering | string; // TODO: Type the record correctly based on Commerce data
    products: ProductArray;
};

export default function SearchResultsGrid({
    // variants,
    lang,
    productTileConfigData,
    // searchParams,
    composerData,
    searchConfigData,
    fullRowAdEntries,
}: {
    // variants: any[];
    lang: LocaleProps;
    productTileConfigData: unknown;
    // searchParams: SearchParams;
    composerData?: ComposerData;
    searchConfigData?: SearchConfig;
    fullRowAdEntries: ReactNode;
}) {
    // const { thirdPartyInfo } = useBrowseContext();
    // const { oneTrust } = thirdPartyInfo;
    // const { isTargetingEnabled } = oneTrust;
    const { searchResult, isSearchResultsLoading } = useLucidWorksContext();
    const { searchPageData } = useCriteo();
    const { isMobile, isTablet } = useCheckScreen();

    const inGridPlacementKey = `viewSearchResults_API_${isMobile ? 'mobile' : 'desktop'}-ingrid`;

    const inGridPlacement = useMemo(() => {
        return searchPageData?.placements?.find(
            (placement: Record<string, Placement[]>) =>
                placement[inGridPlacementKey]
        )?.[inGridPlacementKey]?.[0];
    }, [searchPageData, inGridPlacementKey]);

    const butterflyPlacementKey = `viewSearchResults_API_${isMobile ? 'mobile' : 'desktop'}-Butterfly`;

    const butterflyPlacements = useMemo(() => {
        let placements = [];

        let firstPlacement = searchPageData?.placements?.find(
            (placement: Record<string, Placement[]>) =>
                placement[`${butterflyPlacementKey}1`]
        );
        if (firstPlacement) {
            placements.push(firstPlacement[`${butterflyPlacementKey}1`][0]);
        }

        let secondPlacement = searchPageData?.placements?.find(
            (placement: Record<string, Placement[]>) =>
                placement[`${butterflyPlacementKey}2`]
        );
        if (secondPlacement) {
            placements.push(secondPlacement[`${butterflyPlacementKey}2`][0]);
        }

        return placements;
    }, [searchPageData, butterflyPlacementKey]);

    const analytics = useCriteoAnalytics({
        placement: inGridPlacement,
    });

    let maxColumns = 4;
    if (isTablet) {
        maxColumns = 3;
    } else if (isMobile) {
        maxColumns = 2;
    }
    const singleItemGridSize = 12 / maxColumns; // This determines the size of the grid items

    if (isSearchResultsLoading) {
        return (
            <>
                <Grid
                    container
                    style={{ margin: 0 }}
                    spacing={6}
                    id={SEARCH_RESULT_CONTAINER_ID}
                >
                    {[...Array(12).keys()].map((_, index) => {
                        return (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                xs={singleItemGridSize}
                                key={`criteo_${index}_skeleton`}
                            >
                                <ProductTileSkeleton />
                            </Grid>
                        );
                    })}
                </Grid>
            </>
        );
    }

    if (!searchResult) {
        return null;
    }

    const { docs } = searchResult;

    let docsToRender = docs;

    const rowToRenderFullRowAdOn = searchConfigData?.ingrid_placement_row_nth_;

    const hasInGridFullRowAds = !!composerData?.inGridPlacements?.length;

    const columnToRenderSponsoredProductsOn = maxColumns;

    // Get the first butterfly position found
    const butterflyPositionConfig =
        searchConfigData?.external_ingrid_position?.find(
            ({ placement_type }) => {
                return placement_type === 'criteo_butterFly';
            }
        );

    const butterflyPlacementRowNth =
        butterflyPositionConfig?.placement_row_nth_;
    let rowToRenderButterflyOn = butterflyPlacementRowNth?.[0];

    if (
        (searchConfigData?.enable_criteo_sponsored_products &&
            inGridPlacement?.products?.length) ||
        (typeof rowToRenderButterflyOn === 'number' &&
            butterflyPlacements?.length)
    ) {
        let currentCriteoProductTileIndex = 0;
        let currentCriteoButterflyIndex = 0;
        docsToRender = docs.reduce((newDocs, doc) => {
            const placeSponsoredAd = () => {
                let newDocsLength = newDocs.length;
                if (
                    (inGridPlacement?.products?.[
                        currentCriteoProductTileIndex
                    ] && newDocsLength + 1) %
                        columnToRenderSponsoredProductsOn ===
                    0
                ) {
                    newDocs.push({
                        isCriteoProductTileAd: true,
                        criteoIndex: currentCriteoProductTileIndex,
                    });
                    currentCriteoProductTileIndex += 1;
                }
            };
            const placeButterflyAd = () => {
                let newDocsLength = newDocs.length;
                let row = newDocsLength / maxColumns;

                if (
                    typeof rowToRenderButterflyOn === 'number' &&
                    butterflyPlacements?.[currentCriteoButterflyIndex] &&
                    (row + 1) % rowToRenderButterflyOn === 0
                ) {
                    newDocs.push({
                        isCriteoButterflyAd: true,
                        criteoIndex: currentCriteoButterflyIndex,
                    });
                    newDocs.push({
                        isCriteoButterflyFiller: true,
                    });
                    currentCriteoButterflyIndex += 1;
                    if (!butterflyPositionConfig?.iterative) {
                        rowToRenderButterflyOn =
                            butterflyPlacementRowNth?.[
                                currentCriteoButterflyIndex
                            ];
                    }
                }
            };

            // Try to place sponsored ad
            placeSponsoredAd();
            // Try to place buttery ad
            placeButterflyAd();
            // Try to place sponsored ad if new length calls for it (this handles the case of three)
            placeSponsoredAd();

            newDocs.push(doc);

            return newDocs;
        }, []);

        if (
            docsToRender.length % maxColumns === maxColumns - 1 &&
            inGridPlacement?.products?.[currentCriteoProductTileIndex + 1]
        ) {
            docsToRender.push({
                isCriteoProductTileAd: true,
                criteoIndex: currentCriteoProductTileIndex + 1,
            });
        }
    }

    const fullRowAdEntriesArray = Children.toArray(fullRowAdEntries);

    return (
        <Grid
            container
            style={{ margin: 0 }}
            spacing={isMobile ? 4 : 6}
            id={SEARCH_RESULT_CONTAINER_ID}
            sx={{
                '& > div': {
                    borderBottom: `1px solid ${ColorGray500}`,
                    marginBottom: '-1px',
                    paddingTop: '24px',
                    paddingBottom: '24px',
                },
                '& > div:empty': {
                    display: 'none',
                },
                borderBottom: `1px solid ${ColorGray500}`,
            }}
        >
            {docsToRender.map((doc, index) => {
                let ProductTileComponent;
                if (doc.isCriteoProductTileAd) {
                    if (inGridPlacement?.products?.length) {
                        let product = inGridPlacement.products[doc.criteoIndex];

                        if (product) {
                            ProductTileComponent = (
                                <Analytics
                                    analyticData={{
                                        adItem: {
                                            title: 'sponsored products',
                                            type: 'product',
                                            style: 'block',
                                        },
                                        itemCuration: 'criteo',
                                        position: 'in grid',
                                        title: 'sponsored products - search in grid',
                                        trackType: 'ad',
                                    }}
                                >
                                    <CriteoProductTile
                                        key={`${product.ParentSKU}_criteo_${doc.criteoIndex}_tile`}
                                        variant={
                                            product_display_style.product_card_display_style as Variants
                                        }
                                        locale={lang}
                                        showActionButton={
                                            product_display_style.show_action_button
                                        }
                                        showReviews={
                                            product_display_style.show_reviews
                                        }
                                        showCompareProduct={!isMobile}
                                        placement={inGridPlacement}
                                        products={[product]}
                                        inventoryAware={
                                            product_display_style.inventory_aware_listings
                                        }
                                        itemNumber={product.ParentSKU}
                                        // @ts-expect-error missing type
                                        configData={productTileConfigData}
                                        isSponsored={true}
                                        sx={{
                                            height: '100%',
                                        }}
                                        WrapperComponent={(
                                            props: React.PropsWithChildren
                                        ) => {
                                            if (doc.criteoIndex !== 0) {
                                                return (
                                                    <Grid
                                                        xs={singleItemGridSize}
                                                        key={`${product.ParentSKU}_criteo_${doc.criteoIndex}_wrapper`}
                                                    >
                                                        {props.children}
                                                    </Grid>
                                                );
                                            } else {
                                                return (
                                                    <Grid
                                                        xs={singleItemGridSize}
                                                        key={`${product.ParentSKU}_criteo_${doc.criteoIndex}_wrapper`}
                                                    >
                                                        <AnalyticsObserver
                                                            observer={{
                                                                minimumViewTime:
                                                                    analytics.minimumViewTime,
                                                                onLoad: () =>
                                                                    analytics.onPlacementLoad(),
                                                                onView: () =>
                                                                    analytics.onPlacementViewed(),
                                                                viewThreshold:
                                                                    analytics.viewThreshold,
                                                            }}
                                                            sx={{
                                                                height: '100%',
                                                            }}
                                                        >
                                                            {props.children}
                                                        </AnalyticsObserver>
                                                    </Grid>
                                                );
                                            }
                                        }}
                                    />
                                </Analytics>
                            );
                        }
                    } else {
                        // This will render on the server until hydrated on the client to get into the above if statement
                        ProductTileComponent = (
                            <Grid
                                xs={12}
                                key={`criteo_${doc.criteoIndex}_skeleton`}
                            >
                                <ProductTileSkeleton />
                            </Grid>
                        );
                    }
                } else if (
                    doc.isCriteoButterflyAd ||
                    doc.isCriteoButterflyFiller
                ) {
                    if (doc.isCriteoButterflyFiller) {
                        return <></>; // This is to keep the index correct for future tiles, but shouldn't be rendered
                    }
                    const butterflyPlacement =
                        butterflyPlacements[doc.criteoIndex];
                    const products = butterflyPlacement.products;
                    const ParentSKUs =
                        products?.map(
                            ({ ParentSKU }: { ParentSKU: string }) => ParentSKU
                        ) || [];

                    return (
                        <Grid
                            xs={singleItemGridSize * 2}
                            key={`butterfly_${doc.criteoIndex}_${index}`}
                            display="flex"
                        >
                            <Box
                                sx={{
                                    minWidth: '100%',
                                    minHeight: '100%',
                                }}
                            >
                                <CriteoButterflyDisplay
                                    contentStackEntry={{
                                        criteo_placement_name: `butterfly_${doc.criteoIndex}`,
                                        product_display_style,
                                    }}
                                    locale={lang}
                                    products={butterflyPlacement.products}
                                    placement={butterflyPlacement}
                                    ParentSKUs={ParentSKUs}
                                    productTileSx={{
                                        minHeight: isMobile ? '100%' : '97%',
                                        paddingRight: `2px`,
                                    }}
                                />
                            </Box>
                        </Grid>
                    );
                } else {
                    const isTravelItem =
                        doc?.content_type?.includes(TRAVEL_DESTINATION);
                    ProductTileComponent = (
                        <Grid
                            xs={singleItemGridSize}
                            key={`${doc.item_number}_${index}`}
                            display="flex"
                        >
                            {!doc.item_chdi_eligible && (
                                <SearchPageDigitalDataLayer
                                    productData={{
                                        pid: isTravelItem
                                            ? TRAVEL_ITEM_PID
                                            : doc.group_id,
                                        sku: isTravelItem
                                            ? TRAVEL_ITEM_PID
                                            : doc.item_number,
                                        searchDisplayRank: index + 1,
                                    }}
                                />
                            )}
                            <Analytics
                                analyticData={{
                                    component: isTravelItem
                                        ? {
                                              identifier: SEARCH,
                                              type: COMPONENT_TYPE_UI,
                                          }
                                        : undefined,
                                    trackedData: !isTravelItem
                                        ? [
                                              {
                                                  key: KEY_SEARCH_INDEX,
                                                  value: (index + 1).toString(),
                                              },
                                          ]
                                        : undefined,
                                    trackType: isTravelItem
                                        ? TRACK_TYPE_NAVIGATION
                                        : TRACK_TYPE_MULTI_ATTR,
                                }}
                            >
                                <ProductTileUI
                                    variant={
                                        product_display_style.product_card_display_style as Variants
                                    }
                                    locale={lang}
                                    showActionButton={
                                        product_display_style.show_action_button
                                    }
                                    showReviews={
                                        product_display_style.show_reviews
                                    }
                                    lwData={doc}
                                    inventoryAware={
                                        product_display_style.inventory_aware_listings
                                    }
                                    showCompareProduct={!isMobile}
                                    // @ts-expect-error missing type
                                    configData={productTileConfigData}
                                />
                            </Analytics>
                        </Grid>
                    );
                }

                let FullRowAdComponent;
                let row = (index + 1) / maxColumns;

                if (
                    hasInGridFullRowAds &&
                    typeof rowToRenderFullRowAdOn === 'number' &&
                    row % rowToRenderFullRowAdOn === 0
                ) {
                    let adIndex = row / rowToRenderFullRowAdOn - 1;

                    let fullRowAdData =
                        composerData?.inGridPlacements?.[adIndex];

                    if (fullRowAdData) {
                        FullRowAdComponent = fullRowAdEntriesArray[adIndex];
                    }
                }

                return (
                    <React.Fragment key={index}>
                        {ProductTileComponent}
                        {FullRowAdComponent && (
                            <Grid
                                xs={12}
                                key={`${doc.item_number}_${index}_fullRow`}
                            >
                                {FullRowAdComponent}
                            </Grid>
                        )}
                    </React.Fragment>
                );
            })}
        </Grid>
    );
}
