'use client';

import { useEffect, useState } from 'react';

import { updateSearchParams } from '@/components/SearchResultsFacets/utils';
import { getKeyValue } from '@/services/content/business.lib';
import { useLucidWorksContext } from '@/src/services/search/LucidWorksProvider';
import type {
    ContentStackEntryDataProps,
    SearchPageConfigProperties,
} from '@/types/contentStack';
import { Select, Skeleton } from '@costcolabs/forge-components';
import {
    Analytics,
    useQueryParams,
} from '@costcolabs/forge-digital-components';

import { StyledSortContainer, StyledSortSelect } from './styles';

export function SortSelector({
    config,
}: {
    config: ContentStackEntryDataProps<SearchPageConfigProperties>;
}) {
    const properties = config.properties[0];
    const { queryParams: searchParams, setQueryParams } = useQueryParams();
    const { searchResult } = useLucidWorksContext();
    const [selectedValue, setSelectedValue] = useState<
        string | null | undefined
    >(searchParams.get('sortBy'));
    const [sortValueSet, setSortValueSet] = useState(false);

    useEffect(() => {
        let sortBy = searchParams.get('sortBy');

        if (sortBy) {
            setSelectedValue(sortBy);
            setSortValueSet(true);
        } else if (
            searchResult &&
            (searchResult.defaultFusionSort || properties?.buckets[0]!.meta)
        ) {
            setSelectedValue(
                searchResult?.defaultFusionSort
                    ? searchResult.defaultFusionSort.replace(
                          new RegExp(' ', 'g'),
                          '+'
                      )
                    : properties?.buckets[0]!.meta
            );
            setSortValueSet(true);
        }
    }, [searchResult, searchParams, properties?.buckets]);

    const handleDoSort = (meta: string) => {
        setSelectedValue(meta);
        const params = new URLSearchParams(searchParams);

        params.set('sortBy', meta);
        params.delete('page');

        updateSearchParams(setQueryParams, params);
    };

    if (!properties) {
        return null;
    }

    return (
        <form style={{ width: '100%' }}>
            <StyledSortContainer>
                <StyledSortSelect>
                    <Analytics
                        analyticData={{
                            component: {
                                identifier: 'sort by',
                                type: 'ui',
                            },
                            trackType: 'navigation',
                        }}
                    >
                        {sortValueSet || selectedValue ? (
                            <Select
                                label={getKeyValue('sortlabel', config)}
                                options={properties.buckets.map(entry => ({
                                    text: getKeyValue(entry.key, config)!,
                                    value: entry.meta,
                                }))}
                                value={selectedValue || ''}
                                onChange={({ target }) => {
                                    handleDoSort(target.value);
                                }}
                                uniqueId="sort_by"
                            />
                        ) : (
                            // @ts-ignore TODO: export variants or move to const
                            <Skeleton variant="quantitySelector" height={40} />
                        )}
                    </Analytics>
                </StyledSortSelect>
            </StyledSortContainer>
        </form>
    );
}
