export const NO_RESULTS_CONTAINER_ID = 'noResultsContainer';
export const NO_RESULTS_DEVELOPMENT_KEY = 'search-no-results';
export const NO_RESULTS_TITLE_KEY = 'title';
export const NO_RESULTS_SUBTITLE_KEY = 'subtitle';
export const NO_RESULTS_SUGGESTION_TITLE_KEY = 'suggestionheader';
export const NO_RESULTS_SUGGESTION_1_KEY = 'suggestion1';
export const NO_RESULTS_SUGGESTION_2_KEY = 'suggestion2';
export const NO_RESULTS_SUGGESTION_3_KEY = 'suggestion3';
export const NO_RESULTS_BOTTOM_PARAGRAPH_KEY = 'bottomparagraph';
export const NO_RESULTS_LINK_TEXT_KEY = 'linktext';
export const NO_RESULTS_LINK_URL_KEY = 'linkurl';
