import { z } from 'zod';

import { createEnv } from '@t3-oss/env-nextjs';

// See https://env.t3.gg/docs/nextjs for details pertaining to the code below.
export const env = createEnv({
    server: {
        NODE_ENV: z.string().nonempty(),

        CONTENTSTACK_BROWSE_API_KEY: z.string().nonempty(),
        CONTENTSTACK_BROWSE_DELIVERY_TOKEN: z.string().nonempty(),
        CONTENTSTACK_BROWSE_PREVIEW_TOKEN: z.string().nonempty(),
        CONTENTSTACK_BROWSE_ENVIRONMENT: z.string().nonempty(),
        CONTENTSTACK_BROWSE_BRANCH: z.string().nonempty(),
        CONTENTSTACK_BROWSE_API_HOST: z.string().nonempty(),
        CONTENTSTACK_BROWSE_APP_HOST: z.string().nonempty(),
        CONTENTSTACK_BROWSE_PREVIEW_HOST: z.string().nonempty(),
        CONTENTSTACK_BROWSE_REGION: z.string().nonempty(),

        CONTENTSTACK_CONFIG_API_KEY: z.string().nonempty(),
        CONTENTSTACK_CONFIG_DELIVERY_TOKEN: z.string().nonempty(),
        CONTENTSTACK_CONFIG_ENVIRONMENT: z.string().nonempty(),
        CONTENTSTACK_CONFIG_BRANCH: z.string().nonempty(),
        CONTENTSTACK_CONFIG_API_HOST: z.string().nonempty(),
        CONTENTSTACK_CONFIG_APP_HOST: z.string().nonempty(),
        CONTENTSTACK_CONFIG_REGION: z.string().nonempty(),

        SITE: z.string().nonempty(),
        INTERNAL_API_BASE_URL: z.string().nonempty(),
        CONTENT_ENTRY_ID_FOOTER: z.string().nonempty(),
        CONTENT_ENTRY_ID_HEADER: z.string().nonempty(),
        CONTENT_ENTRY_ID_HOMEPAGE: z.string().nonempty(),
        CONTENT_ENTRY_TYPE_HOMEPAGE: z.string().nonempty(),
        CONTENT_ENTRY_ID_SITE_CONFIGURATION: z.string().nonempty(),
        CONTENT_ENTRY_ID_CLIENT_CONFIGURATION: z.string().nonempty(),
        CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION: z.string().nonempty(),

        // Azure B2C settings, used by the NextAuth provider
        AZURE_AD_B2C_CLIENT_SECRET: z.string().nonempty(),

        // NextAuth settings, needed for production deployment (optional during local dev)
        NEXTAUTH_URL: z.string().nonempty(),
        NEXTAUTH_SECRET: z.string().nonempty(),

        LOGROCKET_CUSTOMERRECORDING_APPID: z.string().nonempty(),
        LOGROCKET_BUSINESSUSERRECORDING_APPID: z.string().nonempty(),

        SUPPORTED_LANGUAGES: z.string(),
        DEFAULT_LANGUAGE: z.string(),
        BUILD_NUMBER: z.string().optional(),
        BUILD_BRANCH: z.string().optional(),
        HOST_ENVIRONMENT: z.string().optional(),
    },
    client: {
        // We dont want to expose any of the server env variables to the client, so leave this empty
    },
    runtimeEnv: {
        NODE_ENV: process.env.NODE_ENV,

        SITE: process.env.SITE,
        CONTENT_ENTRY_ID_SITE_CONFIGURATION:
            process.env.CONTENT_ENTRY_ID_SITE_CONFIGURATION,
        CONTENTSTACK_BROWSE_API_KEY: process.env.CONTENTSTACK_BROWSE_API_KEY,
        CONTENTSTACK_BROWSE_DELIVERY_TOKEN:
            process.env.CONTENTSTACK_BROWSE_DELIVERY_TOKEN,
        CONTENTSTACK_BROWSE_PREVIEW_TOKEN:
            process.env.CONTENTSTACK_BROWSE_PREVIEW_TOKEN,
        CONTENTSTACK_BROWSE_ENVIRONMENT:
            process.env.CONTENTSTACK_BROWSE_ENVIRONMENT,
        CONTENTSTACK_BROWSE_BRANCH: process.env.CONTENTSTACK_BROWSE_BRANCH,
        CONTENTSTACK_BROWSE_API_HOST: process.env.CONTENTSTACK_BROWSE_API_HOST,
        CONTENTSTACK_BROWSE_APP_HOST: process.env.CONTENTSTACK_BROWSE_APP_HOST,
        CONTENTSTACK_BROWSE_PREVIEW_HOST:
            process.env.CONTENTSTACK_BROWSE_PREVIEW_HOST,
        CONTENTSTACK_BROWSE_REGION: process.env.CONTENTSTACK_BROWSE_REGION,
        CONTENTSTACK_CONFIG_API_KEY: process.env.CONTENTSTACK_CONFIG_API_KEY,
        CONTENTSTACK_CONFIG_DELIVERY_TOKEN:
            process.env.CONTENTSTACK_CONFIG_DELIVERY_TOKEN,
        CONTENTSTACK_CONFIG_ENVIRONMENT:
            process.env.CONTENTSTACK_CONFIG_ENVIRONMENT,
        CONTENTSTACK_CONFIG_BRANCH: process.env.CONTENTSTACK_CONFIG_BRANCH,
        CONTENTSTACK_CONFIG_API_HOST: process.env.CONTENTSTACK_CONFIG_API_HOST,
        CONTENTSTACK_CONFIG_APP_HOST: process.env.CONTENTSTACK_CONFIG_APP_HOST,
        CONTENTSTACK_CONFIG_REGION: process.env.CONTENTSTACK_CONFIG_REGION,
        CONTENT_ENTRY_ID_FOOTER: process.env.CONTENT_ENTRY_ID_FOOTER,
        CONTENT_ENTRY_ID_HEADER: process.env.CONTENT_ENTRY_ID_HEADER,
        CONTENT_ENTRY_ID_HOMEPAGE: process.env.CONTENT_ENTRY_ID_HOMEPAGE,
        CONTENT_ENTRY_TYPE_HOMEPAGE: process.env.CONTENT_ENTRY_TYPE_HOMEPAGE,
        CONTENT_ENTRY_ID_CLIENT_CONFIGURATION:
            process.env.CONTENT_ENTRY_ID_CLIENT_CONFIGURATION,
        CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION:
            process.env.CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION,
        INTERNAL_API_BASE_URL: process.env.INTERNAL_API_BASE_URL,
        AZURE_AD_B2C_CLIENT_SECRET: process.env.AZURE_AD_B2C_CLIENT_SECRET,
        NEXTAUTH_URL: process.env.NEXTAUTH_URL,
        NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
        LOGROCKET_CUSTOMERRECORDING_APPID:
            process.env.LOGROCKET_CUSTOMERRECORDING_APPID,
        LOGROCKET_BUSINESSUSERRECORDING_APPID:
            process.env.LOGROCKET_BUSINESSUSERRECORDING_APPID,
        SUPPORTED_LANGUAGES: process.env.SUPPORTED_LANGUAGES,
        DEFAULT_LANGUAGE: process.env.DEFAULT_LANGUAGE,
        BUILD_NUMBER: process.env.BUILD_NUMBER,
        BUILD_BRANCH: process.env.BUILD_BRANCH,
        HOST_ENVIRONMENT: process.env.HOST_ENVIRONMENT,
    },
});
