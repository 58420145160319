import { Box, styled } from '@mui/material';

export const StyledSortContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

export const StyledSortSelect = styled(Box)({
    width: '300px',
    '@media (max-width: 767px)': {
        width: '100%',
    },
});
