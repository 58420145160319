'use client';

import { getKeyValue } from '@/services/content/business.lib';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import { Divider, Link } from '@costcolabs/forge-components';
import {
    Analytics,
    useCheckScreen,
} from '@costcolabs/forge-digital-components';
import { Box } from '@mui/material';

import { DepartmentConfigProperties } from './types';
import { splitColumns } from './utils';

import {
    DepartmentLinkStyle,
    DepartmentListItemStyle,
    DepartmentListStyle,
    DepartmentListTitleStyle,
} from './styles';

export function DepartmentListView({
    listEntry,
}: {
    listEntry: ContentStackEntryDataProps<DepartmentConfigProperties>;
}) {
    const { isMobile } = useCheckScreen();

    const properties = listEntry.properties[0];

    if (!properties) {
        return null;
    }

    const columns = splitColumns(properties.list, isMobile ? 2 : 4);

    return (
        <Box>
            <Divider />
            <h2 style={DepartmentListTitleStyle}>{properties.title}</h2>
            <Box component="ul" sx={DepartmentListStyle}>
                {columns.map(column =>
                    column.map(link => (
                        <li style={DepartmentListItemStyle} key={link.url}>
                            <Analytics
                                analyticData={{
                                    component: {
                                        identifier: 'Search',
                                        trackedData: [
                                            'Department List',
                                            getKeyValue(link.key, listEntry),
                                        ],
                                        type: 'nav',
                                    },
                                    trackType: 'navigation',
                                }}
                            >
                                <Link href={link.url} sx={DepartmentLinkStyle}>
                                    {getKeyValue(link.key, listEntry)}
                                </Link>
                            </Analytics>
                        </li>
                    ))
                )}
            </Box>
        </Box>
    );
}
