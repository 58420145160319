import { ReactNode } from 'react';

import { FilterGroup } from '@/components/FilterGroup';
import { IN_WAREHOUSE_BUCKET_VALUE } from '@/constants/index';
import type { Facet, FacetBucket } from '@/src/types/searchQuery';
import type {
    ContentStackEntryDataProps,
    LocaleProps,
} from '@/types/contentStack';
import { PageType } from '@/types/pages/search';
import { Divider } from '@costcolabs/forge-components';
import { Box } from '@mui/material';

// Facet Label: Hide Unavailable Items
export function ProgramEligibilityFilterGroup({
    facet,
    config,
    WarehouseSelectorComponent,
    DeliveryLocationSelectorComponent,
    locale,
    pageType,
}: {
    facet: Facet;
    config: ContentStackEntryDataProps;
    WarehouseSelectorComponent: ReactNode;
    DeliveryLocationSelectorComponent: ReactNode;
    locale: LocaleProps;
    pageType: PageType;
}) {
    let InWarehouseCount = 0;
    const facetBucketsInWarehouseRemoved: FacetBucket[] = [];
    const facetInWarehouseBucket: FacetBucket[] = [];

    facet.buckets.forEach((bucket: FacetBucket) => {
        const bucketValLower = String(bucket.val).toLowerCase();
        if (bucketValLower === IN_WAREHOUSE_BUCKET_VALUE) {
            InWarehouseCount = bucket.count;
            facetInWarehouseBucket.push(bucket);
        } else {
            facetBucketsInWarehouseRemoved.push(bucket);
        }
    });

    const facetInWarehouseRemoved = {
        ...facet,
        buckets: facetBucketsInWarehouseRemoved,
    };

    const facetInWarehouse = {
        ...facet,
        buckets: facetInWarehouseBucket,
    };

    return (
        <>
            <Divider />
            <Box
                sx={{
                    padding: '10px 0',
                    '& button': { fontSize: 14, textDecoration: 'underline' },
                }}
            >
                {DeliveryLocationSelectorComponent}
            </Box>
            <FilterGroup
                facet={facetInWarehouseRemoved}
                config={config}
                locale={locale}
                pageType={pageType}
            />
            {InWarehouseCount > 0 && (
                <>
                    <Box
                        sx={{
                            paddingBottom: '10px',
                            '& button': {
                                fontSize: 14,
                                textDecoration: 'underline',
                            },
                        }}
                    >
                        {WarehouseSelectorComponent}
                    </Box>
                    <FilterGroup
                        facet={facetInWarehouse}
                        config={config}
                        locale={locale}
                        pageType={pageType}
                    />
                </>
            )}
        </>
    );
}
