'use client';

import {
    FILTER_REMOVE,
    FILTER_VAL,
    TRACK_TYPE_MULTI_ATTR,
} from '@/components/Analytics/constants';
import { updateSearchParams } from '@/components/SearchResultsFacets/utils';
import { getKeyValue } from '@/services/content/business.lib';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import { Button } from '@costcolabs/forge-components';
import {
    Analytics,
    useQueryParams,
} from '@costcolabs/forge-digital-components';

import { CLEAR_TEXT_KEY, CLEAR_TEXT_KEY_A11Y } from './constants';
import { generateClearedParams } from './utils';

export function ClearAllFilters({
    config,
}: {
    config: ContentStackEntryDataProps;
}) {
    const { queryParams: searchParams, setQueryParams } = useQueryParams();

    const clearText = getKeyValue(CLEAR_TEXT_KEY, config);
    const clearTextAriaLabel = getKeyValue(CLEAR_TEXT_KEY_A11Y, config);

    return (
        <Analytics
            analyticData={{
                trackedData: [
                    { key: FILTER_REMOVE, value: clearText },
                    { key: FILTER_VAL, value: clearText },
                ],
                trackType: TRACK_TYPE_MULTI_ATTR,
            }}
        >
            <Button
                variant="text-button"
                sx={{ float: 'right', fontSize: '1rem' }}
                onClick={() => {
                    updateSearchParams(
                        setQueryParams,
                        generateClearedParams(searchParams)
                    );
                }}
                aria-label={clearTextAriaLabel}
            >
                {clearText}
            </Button>
        </Analytics>
    );
}
