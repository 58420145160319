import { unstable_cache } from 'next/cache';

import { env } from '@/src/env';
import {
    ConfigStack,
    ContentStack,
} from '@costcolabs/forge-digital-components';

import { CONFIGSTACK } from './stackClient';

export const ConfigContentType = {
    ConfigurationSetting: 'Configuration_Setting',
    FeatureToggle: 'Feature_Toggle',
    ModuleSettings: '_module_settings',
    PageSettings: '_page_settings',
    ServiceConfiguration: 'Service_Configuration',
} as const;

export type ContentTypeProps =
    (typeof ConfigContentType)[keyof typeof ConfigContentType];

export async function getConfigurationSettingByEntryId<T>(
    contentType: ContentTypeProps,
    entryId: string
): Promise<T> {
    try {
        return await CONFIGSTACK.ContentType(contentType)
            .Entry(entryId)
            .language(env.CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION)
            .toJSON()
            .fetch();
    } catch (err) {
        throw new Error(
            `Error fetching config type: ${contentType} with entry id: ${entryId}`
        );
    }
}

export const _getConfigurationSettingByDevelopmentKey = async <T>(
    contentType: ContentTypeProps,
    developmentKey: string
): Promise<T> => {
    try {
        console.info(`Fetching Config Stack Settings - ${developmentKey}`);
        return await ConfigStack.stack
            .ContentType(contentType)
            .Query()
            .where('development_key', developmentKey)
            .language(
                ConfigStack.env
                    .CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION ?? ''
            )
            .toJSON()
            .findOne();
    } catch (err) {
        console.error(
            `Error fetching Config Stack Settings - ${developmentKey}, ${JSON.stringify(err, null, 2)}`
        );
        // If you don't rethrow, typescript will complain that the function does not return a value
        throw new Error(
            `An error occurred in function getConfigurationSettingByDevelopmentKey - ${developmentKey}: ${JSON.stringify(err, null, 2)}`
        );
    }
};

const cached_getConfigurationSettingByDevelopmentKey = unstable_cache(
    _getConfigurationSettingByDevelopmentKey,
    ['search_config_getConfigurationSettingByDevelopmentKey'],
    { revalidate: 1800 }
);

export const getConfigurationSettingByDevelopmentKey = async <T>(
    contentType: ContentTypeProps,
    developmentKey: string
): Promise<T> => {
    const fn =
        typeof window === 'undefined'
            ? cached_getConfigurationSettingByDevelopmentKey
            : _getConfigurationSettingByDevelopmentKey;

    return fn(contentType, developmentKey);
};

export const _getContentStackDataByDevelopmentKey = async <T>(
    contentType: ContentTypeProps,
    developmentKey: string,
    locale: string
): Promise<T> => {
    try {
        console.info(`Fetching Content Stack Settings - ${developmentKey}`);
        return await ContentStack.stack
            .ContentType(contentType)
            .Query()
            .where('development_key', developmentKey)
            .language(locale)
            .toJSON()
            .findOne();
    } catch (err) {
        console.error(
            `Error fetching Content Stack Settings - ${developmentKey}, ${JSON.stringify(err, null, 2)}`
        );
        throw new Error(
            `An error ocurred in getContentStackDataByDevelopmentKey.
        contentType: ${contentType},
        developmentKey: ${developmentKey},
        locale: ${locale},
        ${JSON.stringify(err, null, 2)}`
        );
    }
};

const cached_getContentStackDataByDevelopmentKey = unstable_cache(
    _getContentStackDataByDevelopmentKey,
    ['search_config_getContentStackDataByDevelopmentKey'],
    { revalidate: 1800 }
);

export const getContentStackDataByDevelopmentKey = async <T>(
    contentType: ContentTypeProps,
    developmentKey: string,
    locale: string
): Promise<T> => {
    const fn =
        typeof window === 'undefined'
            ? cached_getContentStackDataByDevelopmentKey
            : _getContentStackDataByDevelopmentKey;

    return fn(contentType, developmentKey, locale);
};

const _getConfigurationSettingByConfigKey = async <T>(
    contentType: ContentTypeProps,
    configKey: string
): Promise<T> => {
    try {
        const result = await ConfigStack.stack
            .ContentType(contentType)
            .Query()
            .where('configkey', configKey)
            .language(
                ConfigStack.env
                    .CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION ?? ''
            )
            .toJSON()
            .findOne();
        if (process.env.LOG_CONTENTSTACK_CALLS === 'true') {
            console.log(
                `Config stack data fetched successfully using getConfigurationSettingByConfigKey with
            contentType: ${contentType},
            configKey: ${configKey},
            resulting in data: ${typeof result === 'object' && JSON.stringify(result)}`
            );
        }
        return result;
    } catch (err) {
        const errorString = `An error occurred in Config stack getConfigurationSettingByConfigKey with 
      contentType: ${contentType},
      configKey: ${configKey},
      resulting in error: ${typeof err === 'object' && JSON.stringify(err)}`;
        console.error(errorString);
        throw new Error(errorString);
    }
};

const cached_getConfigurationSettingByConfigKey = unstable_cache(
    _getConfigurationSettingByConfigKey,
    ['search_config_getConfigurationSettingByConfigKey'],
    { revalidate: 1800 }
);

export const getConfigurationSettingByConfigKey = async <T>(
    contentType: ContentTypeProps,
    configKey: string
): Promise<T> => {
    const fn =
        typeof window === 'undefined'
            ? cached_getConfigurationSettingByConfigKey
            : _getConfigurationSettingByConfigKey;

    return fn(contentType, configKey);
};
