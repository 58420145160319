'use client';

import { ReactNode } from 'react';

import { useLucidWorksContext } from '@/src/services/search/LucidWorksProvider';
import { useBrowseContext } from '@costcolabs/forge-digital-components';

const CookieBasedContentLoaderWithLucidWorks = ({
    children,
}: {
    children: ReactNode;
}) => {
    const { thirdPartyInfo } = useBrowseContext();
    const { searchResult } = useLucidWorksContext();
    const isWindowAvailable = typeof window !== 'undefined';
    const { oneTrust } = thirdPartyInfo || {};
    const { isTargetingEnabled } = oneTrust || {};
    const isAdTargetingEnabled = searchResult?.isAdTargetingEnabled;

    return (
        <>
            {isWindowAvailable &&
                isTargetingEnabled &&
                isAdTargetingEnabled &&
                children}
        </>
    );
};

export default CookieBasedContentLoaderWithLucidWorks;
