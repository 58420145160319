'use client';

import {
    fullyDecodeURI,
    parseRefineParamToLabels,
} from '@/components/SearchResultsFacets/utils';
import { getKeyValue } from '@/services/content/business.lib';
import { useLucidWorksContext } from '@/src/services/search/LucidWorksProvider';
import type {
    CategoryLandingPage,
    ContentStackEntryDataProps,
    SearchRuleEntry,
} from '@/types/contentStack';
import { Skeleton } from '@costcolabs/forge-components';
import { useQueryParams } from '@costcolabs/forge-digital-components';

import {
    CORRECTED_RESULTS_TEXT_KEY,
    CORRECTED_TOKEN,
    DIRECT_MATCH_TEXT_KEY,
    NO_RESULTS_TEXT_KEY,
    RESULT_COUNT_TOKEN,
    SEARCH_TOKEN,
    SEMANTIC_RESULTS_TEXT_KEY,
} from './constants';

import { Header } from './styles';

export function SearchResultsHeader({
    keywordEntry,
    facetConfig,
    config,
}: {
    keywordEntry?: SearchRuleEntry | CategoryLandingPage;
    facetConfig: ContentStackEntryDataProps;
    config: ContentStackEntryDataProps;
}) {
    const { queryParams: searchParamsMap } = useQueryParams();
    const searchParams = Object.fromEntries(searchParamsMap.entries());
    const { searchResult, isSearchResultsLoading } = useLucidWorksContext();
    if (isSearchResultsLoading) {
        return (
            <Header>
                {/* @ts-ignore TODO: export this or let text as variant */}
                <Skeleton variant="textHeader" />
            </Header>
        );
    }

    if (!searchResult) {
        return null;
    }

    const { pagination, metrics } = searchResult;

    let headerText = getKeyValue(NO_RESULTS_TEXT_KEY, config);

    if (!searchParams.keyword || pagination.totalDocs === 0) {
        return <Header>{headerText}</Header>;
    }

    if (Array.isArray(searchParams.keyword)) {
        searchParams.keyword = searchParams.keyword[0];
    }

    const termToDisplay =
        keywordEntry?.search_page_title || searchParams.keyword;

    // There are a bunch of special cases for showing the header text based on filters applied
    let refine = searchParams.refine;
    if (Array.isArray(refine)) {
        refine = refine[0] as string;
    }
    const refinements = parseRefineParamToLabels(
        fullyDecodeURI(refine),
        facetConfig
    );

    // 7. If we found results for the search term and have a search page title override
    if (keywordEntry?.search_page_title) {
        return <Header>{keywordEntry.search_page_title}</Header>;
    }

    // We ignore the default filters
    const nonDefaultRefinements = refinements.filter(
        ({ filterType }) =>
            filterType !== 'Shop by Price' && filterType !== 'Shop by Location'
    );

    // 3. If only one filter is applied, show the filter label and the search term
    if (nonDefaultRefinements.length === 1) {
        return (
            <Header>
                {nonDefaultRefinements[0]!.label} {termToDisplay}
            </Header>
        );
    }

    // 4. If more than one filter is applied, only show the search term
    if (nonDefaultRefinements.length > 1) {
        return <Header>{termToDisplay}</Header>;
    }

    // 5. If we found nothing and have no correction, but found something from another category or something
    if (metrics.semantic) {
        return (
            <Header>
                {replaceTokens(getKeyValue(SEMANTIC_RESULTS_TEXT_KEY, config))}
            </Header>
        );
    }

    function replaceTokens(template: string | undefined) {
        if (!template) {
            return '';
        }

        return template
            .replace(SEARCH_TOKEN, searchParams.keyword as string)
            .replace(CORRECTED_TOKEN, metrics.correction!)
            .replace(RESULT_COUNT_TOKEN, pagination.totalDocs.toLocaleString());
    }

    // 6. If we found results for a similar search term instead
    if (metrics.correction !== undefined) {
        return (
            <Header>
                {replaceTokens(getKeyValue(CORRECTED_RESULTS_TEXT_KEY, config))}
            </Header>
        );
    }

    // 8. If we found results for the search term, display it with the number of results
    return (
        <Header>
            {replaceTokens(getKeyValue(DIRECT_MATCH_TEXT_KEY, config))}
        </Header>
    );
}
