'use client';

import { A11Y_FILTER_DESCRIBED_BY } from '../../SearchResultsFacets/constants';

import {
    StyledList,
    StyledListItem,
} from '@/components/SearchResultsFacets/styles';
import {
    checkRefinementApplied,
    updateRefinements,
} from '@/components/SearchResultsFacets/utils';
import { getKeyValue } from '@/services/content/business.lib';
import {
    FILTER,
    FILTER_REMOVE,
    FILTER_TYPE_REVIEWS,
    FILTER_VAL,
    TRACK_TYPE_MULTI_ATTR,
} from '@/src/components/Analytics/constants';
import type { Facet } from '@/src/types/searchQuery';
import { ContentStackEntryDataProps } from '@/types/contentStack';
import { Checkbox, Rating } from '@costcolabs/forge-components';
import {
    Analytics,
    useCheckScreen,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export function RatingFilterView({
    config,
    facet,
    resultsText,
}: {
    config: ContentStackEntryDataProps;
    facet: Facet;
    resultsText: string;
}) {
    const { isTablet } = useCheckScreen();
    const { queryParams: searchParams, setQueryParams } = useQueryParams();

    const handleDoRefine = (
        key: string,
        value: string | boolean,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        updateRefinements(
            setQueryParams,
            `${key}-${value}`,
            e.target.checked,
            searchParams
        );
    };
    const ratingText = getKeyValue('a11y.ratingtext', config);
    return (
        // @ts-ignore TODO wtf?
        <StyledList>
            {facet.buckets.map(bucket => {
                const isChecked = checkRefinementApplied(
                    `${facet.facetKey}-${bucket.val}`,
                    searchParams
                );
                return (
                    <StyledListItem
                        style={{
                            display: 'flex',
                            alignContent: 'flex-start',
                        }}
                        key={bucket.val.toString()}
                        data-private={bucket.leads_to_fsa_chdi}
                        data-bi-private={bucket.leads_to_fsa_chdi}
                    >
                        <Analytics
                            analyticData={{
                                trackedData: [
                                    {
                                        key: isChecked ? FILTER_REMOVE : FILTER,
                                        value: FILTER_TYPE_REVIEWS,
                                    },
                                    {
                                        key: FILTER_VAL,
                                        value: bucket.val.toString(),
                                    },
                                ],
                                trackType: TRACK_TYPE_MULTI_ATTR,
                                doNotTrack: bucket.leads_to_fsa_chdi,
                            }}
                        >
                            <Checkbox
                                ariaDescribeText={A11Y_FILTER_DESCRIBED_BY}
                                onChange={event =>
                                    handleDoRefine(
                                        facet.facetKey,
                                        bucket.val,
                                        event
                                    )
                                }
                                isChecked={!!isChecked}
                                // @ts-ignore this works but needs a type override
                                label={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: isTablet
                                                ? 'column'
                                                : 'row',
                                        }}
                                    >
                                        <Rating
                                            value={parseInt(
                                                bucket.val.toString()
                                            )}
                                            ariaLabel={ratingText}
                                            displayAvgRatingAsText={false}
                                            size={isTablet ? 'small' : 'medium'}
                                        />
                                        <Box sx={{ marginLeft: '5px' }}>
                                            {bucket.val}
                                        </Box>
                                        <Box sx={visuallyHidden}>
                                            {`${bucket.count.toLocaleString()} ${resultsText}`}
                                        </Box>
                                    </Box>
                                }
                                id={`${facet.facetKey}-${bucket.val.toString().split(' ').join('+')}`}
                            />
                        </Analytics>

                        <Box aria-hidden>({bucket.count.toLocaleString()})</Box>
                    </StyledListItem>
                );
            })}
        </StyledList>
    );
}
