'use client';

import { PRODUCT_COUNT_LABEL_KEY } from '../../containers/search/constants';
import {
    getFirstItemIndex,
    getLastItemIndex,
    populateProductCount,
} from '../../containers/search/utils';

import { getKeyValue } from '@/services/content/business.lib';
import { useLucidWorksContext } from '@/src/services/search/LucidWorksProvider';
import type {
    ContentStackEntryDataProps,
    SearchPageConfigProperties,
} from '@/types/contentStack';
import { Skeleton, Text } from '@costcolabs/forge-components';
import { useCheckScreen } from '@costcolabs/forge-digital-components';
import { Box, Divider } from '@mui/material';

export function ResultsCount({
    resultsPerPage,
    config,
    view = 'desktop',
}: {
    resultsPerPage: number;
    config: ContentStackEntryDataProps<SearchPageConfigProperties>;
    view?: 'desktop' | 'mobile';
}) {
    const { searchResult, isSearchResultsLoading } = useLucidWorksContext();
    const { isMobile } = useCheckScreen();

    if (isSearchResultsLoading) {
        return (
            <>
                <Box
                    sx={{
                        display: 'none',
                        '@media (max-width: 767px)': {
                            display: 'block',
                        },
                    }}
                >
                    {/* @ts-ignore TODO: export this or let text as variant */}
                    <Skeleton variant="textBody" />
                </Box>
                <Box
                    sx={{
                        width: '300px',
                        '@media (max-width: 767px)': {
                            display: 'none',
                        },
                    }}
                >
                    {/* @ts-ignore TODO: export this or let text as variant */}
                    <Skeleton variant="textBody" />
                </Box>
            </>
        );
    }

    if (!searchResult) {
        return null;
    }

    const firstDisplayedItemIndex = getFirstItemIndex(
        searchResult.pagination.currentPage,
        resultsPerPage
    );

    let lastDisplayedItemIndex = getLastItemIndex(
        searchResult.pagination.currentPage,
        resultsPerPage,
        searchResult.pagination.totalDocs
    );

    if (view === 'desktop' && isMobile) {
        return null;
    }

    if (view === 'mobile' && !isMobile) {
        return null;
    }

    return (
        <>
            <Box
                sx={{
                    display: 'none',
                    flexShrink: '0',
                    '@media (max-width: 767px)': {
                        display: 'block',
                        marginLeft: '5px',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Text component={'p'} variant="t5">
                        {populateProductCount(
                            getKeyValue(PRODUCT_COUNT_LABEL_KEY, config),
                            {
                                firstDisplayedItemIndex,
                                lastDisplayedItemIndex,
                                totalItems: searchResult.pagination.totalDocs,
                            }
                        )}
                    </Text>
                </Box>

                <Divider
                    sx={{
                        marginTop: '10px',
                        marginBottom: '20px',
                        '@media (max-width: 786px)': { display: 'none' },
                    }}
                />
            </Box>
            <Box
                sx={{
                    width: '300px',
                    textAlign: 'right',
                    '@media (max-width: 767px)': {
                        display: 'none',
                    },
                }}
            >
                <Text component={'p'} variant="t5">
                    {populateProductCount(
                        getKeyValue(PRODUCT_COUNT_LABEL_KEY, config),
                        {
                            firstDisplayedItemIndex,
                            lastDisplayedItemIndex,
                            totalItems: searchResult.pagination.totalDocs,
                        }
                    )}
                </Text>
            </Box>
        </>
    );
}
