'use client';

import { ReactNode, useEffect, useState } from 'react';

import { useLucidWorksContext } from '@/src/services/search/LucidWorksProvider';
import { useBrowseContext } from '@costcolabs/forge-digital-components';

const AdobeClient = ({ children }: { children: ReactNode }) => {
    const [isWindowAvailable, setIsWindowAvailable] = useState(false);
    const { thirdPartyInfo } = useBrowseContext();
    const { searchResult } = useLucidWorksContext();
    const { oneTrust } = thirdPartyInfo;
    const { isTargetingEnabled } = oneTrust;

    useEffect(() => {
        if (typeof window !== 'undefined') {
            // The Adobe script we load contains logic that will load additional scripts. Setting
            // digitalData.sitePlatform to "react" allows the script to know which additional scripts to use.
            window.digitalData = {
                ...window.digitalData,
                sitePlatform: 'react',
            };
            setIsWindowAvailable(true);
        }
    }, []);

    return (
        <>
            {isWindowAvailable &&
                isTargetingEnabled &&
                searchResult?.isAdTargetingEnabled &&
                children}
        </>
    );
};

export { AdobeClient };
