'use client';

import { A11Y_FILTER_DESCRIBED_BY } from '../../SearchResultsFacets/constants';

import { StyledRowContainer } from '@/components/SearchResultsFacets/styles';
import {
    checkRefinementApplied,
    updateRefinements,
} from '@/components/SearchResultsFacets/utils';
import { getKeyValue } from '@/services/content/business.lib';
import {
    FILTER,
    FILTER_REMOVE,
    FILTER_TYPE_PRICE,
    FILTER_VAL,
    TRACK_TYPE_MULTI_ATTR,
} from '@/src/components/Analytics/constants';
import type { Facet } from '@/src/types/searchQuery';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import { Checkbox, List } from '@costcolabs/forge-components';
import { useQueryParams } from '@costcolabs/forge-digital-components';
import { Analytics } from '@costcolabs/forge-digital-components';
import { Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export function PricingFilterView({
    facet,
    resultsText,
    config,
}: {
    facet: Facet;
    resultsText: string;
    config: ContentStackEntryDataProps;
}) {
    const { queryParams: searchParams, setQueryParams } = useQueryParams();
    const handleDoRefine = (
        key: string,
        value: string | boolean,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        updateRefinements(
            setQueryParams,
            `${key}-${value}`,
            e.target.checked,
            searchParams
        );
    };
    const showMoreText = getKeyValue('facet.showmoretext', config);
    return (
        // @ts-ignore TODO wtf?
        <List
            variant="ExpandableList"
            showMoreText={showMoreText}
            ariaLabeledById={facet.facetKey}
            buttonId={`show_more_${facet.label.toLowerCase().split(' ').join('_')}`}
        >
            {facet.buckets.map(bucket => {
                const isChecked = checkRefinementApplied(
                    `${facet.facetKey}-[${bucket.low} TO ${bucket.high}]`,
                    searchParams
                );

                return (
                    <StyledRowContainer
                        key={bucket.val.toString()}
                        data-private={bucket.leads_to_fsa_chdi}
                        data-bi-private={bucket.leads_to_fsa_chdi}
                    >
                        <Analytics
                            analyticData={{
                                trackedData: [
                                    {
                                        key: isChecked ? FILTER_REMOVE : FILTER,
                                        value: FILTER_TYPE_PRICE,
                                    },
                                    {
                                        key: FILTER_VAL,
                                        value: bucket.val.toString(),
                                    },
                                ],
                                trackType: TRACK_TYPE_MULTI_ATTR,
                                doNotTrack: bucket.leads_to_fsa_chdi,
                            }}
                        >
                            <Checkbox
                                ariaDescribeText={A11Y_FILTER_DESCRIBED_BY}
                                onChange={event =>
                                    handleDoRefine(
                                        facet.facetKey,
                                        `[${bucket.low} TO ${bucket.high}]`,
                                        event
                                    )
                                }
                                label={
                                    (
                                        <>
                                            <Box sx={visuallyHidden}>
                                                {`${facet.label}`}
                                            </Box>
                                            {bucket.val.toString()}{' '}
                                            <Box sx={visuallyHidden}>
                                                {`${bucket.count.toLocaleString()} ${resultsText}`}
                                            </Box>
                                        </>
                                    ) as unknown as string
                                }
                                isChecked={!!isChecked}
                                id={`${facet.facetKey}-${bucket.val.toString().split(' ').join('+')}`}
                            />
                        </Analytics>

                        <Box
                            id={`${facet.facetKey}-${bucket.val.toString().split(' ').join('+')}`}
                            aria-hidden
                        >
                            ({bucket.count.toLocaleString()})
                        </Box>
                    </StyledRowContainer>
                );
            })}
        </List>
    );
}
