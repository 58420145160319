'use client';

import {
    FILTER,
    FILTER_VAL,
    SHOP_BY_PREFIX,
    TRACK_TYPE_MULTI_ATTR,
} from '@/components/Analytics/constants';
import { appendCategoryUrl } from '@/services/search/utils';
import { getKeyValue } from '@/src/services/content/business.lib';
import type { Breadcrumb, Facet } from '@/src/types/searchQuery';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import { PageType } from '@/types/pages/search';
import { Link, List, Text } from '@costcolabs/forge-components';
import {
    ColorTextDefault,
    SpaceMd,
    SpaceXs,
} from '@costcolabs/forge-design-tokens';
import {
    Analytics,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { ChevronLeft } from '@costcolabs/forge-icons';
import { Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import {
    CHILD_CATEGORY_A11Y_TEXT,
    PARENT_CATEGORY_A11Y_TEXT,
} from './constants';

export function CategoryFilterView({
    facet,
    resultsText,
    config,
    pageType,
    breadcrumb,
}: {
    facet: Facet;
    resultsText: string;
    config: ContentStackEntryDataProps;
    pageType: PageType;
    breadcrumb?: Breadcrumb;
}) {
    const { queryParams: params } = useQueryParams();
    const isCategoryPage = pageType === 'category';

    const getKeywordParam = (): string => {
        return params.get('keyword') || '';
    };
    const showMoreText = getKeyValue('facet.showmoretext', config);

    if (!facet.buckets || facet.buckets.length === 0) {
        return null;
    }

    return (
        <>
            {/* to be rendered only for CLP */}
            {isCategoryPage && (
                // @ts-ignore TODO wtf?
                <List variant="ExpandableList">
                    {breadcrumb?.map((category, index) => {
                        // last category in breadcrumb chain will be the current category page
                        const currentCategory = index === breadcrumb.length - 1;
                        return !currentCategory ? (
                            <Analytics
                                analyticData={{
                                    trackedData: [
                                        {
                                            key: FILTER,
                                            value: SHOP_BY_PREFIX + facet.label,
                                        },
                                        {
                                            key: FILTER_VAL,
                                            value: category.name,
                                        },
                                    ],
                                    trackType: TRACK_TYPE_MULTI_ATTR,
                                }}
                            >
                                <Link
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flext-start',
                                        marginBottom: SpaceMd,
                                    }}
                                    href={appendCategoryUrl(category.url)}
                                    aria-label={`${category.name}, ${getKeyValue(PARENT_CATEGORY_A11Y_TEXT, config)}`}
                                >
                                    {!currentCategory && (
                                        <ChevronLeft
                                            // @ts-ignore TODO wtf?
                                            sx={{
                                                paddingRight: SpaceXs,
                                                color: ColorTextDefault,
                                            }}
                                        />
                                    )}
                                    <Text
                                        variant="t5"
                                        sx={{
                                            textDecoration: 'underline',
                                            color: ColorTextDefault,
                                        }}
                                    >
                                        {category.name}
                                    </Text>
                                </Link>
                            </Analytics>
                        ) : (
                            <Text
                                variant="t5"
                                sx={{
                                    color: ColorTextDefault,
                                    paddingLeft: '26px',
                                }}
                                aria-current="page"
                            >
                                {category.name}
                            </Text>
                        );
                    })}
                </List>
            )}

            {/* @ts-ignore TODO wtf? */}
            <List
                variant="ExpandableList"
                showMoreText={showMoreText}
                ariaLabeledById={facet.facetKey}
                buttonId={`show_more_${facet.label.toLowerCase().split(' ').join('_')}`}
                defaultItemsToExpose={10}
            >
                {facet.buckets.map(bucket => {
                    const nesting = bucket.val.toString().split('|');
                    const s = nesting[nesting.length - 1];

                    return (
                        <Analytics
                            key={bucket.val.toString()}
                            analyticData={{
                                trackedData: [
                                    {
                                        key: FILTER,
                                        value: SHOP_BY_PREFIX + facet.label,
                                    },
                                    {
                                        key: FILTER_VAL,
                                        value: s ? s : '',
                                    },
                                ],
                                trackType: TRACK_TYPE_MULTI_ATTR,
                                doNotTrack: bucket.leads_to_fsa_chdi,
                            }}
                        >
                            <Box
                                key={bucket.val.toString()}
                                sx={{
                                    paddingLeft: isCategoryPage
                                        ? '.625rem'
                                        : '',
                                    display: 'flex',
                                    alignItems: 'flext-start',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {/** Category links always route to the .html page with just a keyword */}
                                <Link
                                    automation-id={`${facet.facetKey}`}
                                    href={
                                        isCategoryPage
                                            ? appendCategoryUrl(bucket.meta)
                                            : `${bucket.meta}?keyword=${getKeywordParam()}`
                                    }
                                    underline="always"
                                    aria-label={`${s}, ${getKeyValue(CHILD_CATEGORY_A11Y_TEXT, config)}`}
                                    sx={{
                                        color: ColorTextDefault,
                                        textDecoration: 'underline',
                                    }}
                                >
                                    <Box component={'span'}>{s}</Box>
                                </Link>

                                <Box
                                    component={'span'}
                                    id={`${facet.facetKey}-${bucket.val.toString()}`}
                                    sx={{
                                        color: 'rgb(51, 51, 51)',
                                    }}
                                >
                                    ({bucket.count.toLocaleString()})
                                    <Box sx={visuallyHidden}>{resultsText}</Box>
                                </Box>
                            </Box>
                        </Analytics>
                    );
                })}
            </List>
        </>
    );
}
