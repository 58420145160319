import type { ReadonlyURLSearchParams } from 'next/navigation';

import { BASE_QUERY_PARAMS } from './constants';

/**
 * Strips off all the query params except the base ones to reset user state
 */
export const generateClearedParams = (
    searchParams: ReadonlyURLSearchParams
) => {
    const existingParams = new URLSearchParams(searchParams);
    const newParams = new URLSearchParams();

    for (const [key, value] of existingParams.entries()) {
        if (BASE_QUERY_PARAMS.includes(key)) {
            newParams.set(key, value);
        }
    }

    return newParams;
};
