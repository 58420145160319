'use client';

import { ITEM_PROGRAM_FACET_KEY } from '@/constants/index';
import {
    ITEM_CATEGORY_FACET_KEY,
    ITEM_LOCATION_FACET_KEY,
    ITEM_LOCATION_PRICING_FACET_KEY,
    ITEM_RATING_FACET_KEY,
} from '@/constants/index';
import { getKeyValue } from '@/services/content/business.lib';
import {
    CategoryFilterView,
    DefaultFilterView,
    LocationFilterView,
    PricingFilterView,
    ProgramFilterView,
    RatingFilterView,
} from '@/src/components/FilterGroup/views';
import type { Breadcrumb, Facet } from '@/src/types/searchQuery';
import type {
    ContentStackEntryDataProps,
    LocaleProps,
} from '@/types/contentStack';
import { PageType } from '@/types/pages/search';

export function FilterGroup({
    facet,
    config,
    locale,
    pageType,
    breadcrumb,
}: {
    facet: Facet;
    config: ContentStackEntryDataProps;
    locale: LocaleProps;
    pageType: PageType;
    breadcrumb?: Breadcrumb;
}) {
    const resultsText = getKeyValue('a11y.resultstext', config);
    switch (facet.facetKey.toLowerCase()) {
        case ITEM_CATEGORY_FACET_KEY:
            return (
                <CategoryFilterView
                    facet={facet}
                    resultsText={resultsText}
                    config={config}
                    pageType={pageType}
                    breadcrumb={breadcrumb}
                />
            );

        case ITEM_PROGRAM_FACET_KEY:
            return (
                <ProgramFilterView
                    facet={facet}
                    config={config}
                    resultsText={resultsText}
                    locale={locale}
                />
            );

        case ITEM_LOCATION_FACET_KEY:
            return (
                <LocationFilterView
                    facet={facet}
                    config={config}
                    resultsText={resultsText}
                />
            );

        case ITEM_LOCATION_PRICING_FACET_KEY:
            return (
                <PricingFilterView
                    facet={facet}
                    resultsText={resultsText}
                    config={config}
                />
            );

        case ITEM_RATING_FACET_KEY:
            return (
                <RatingFilterView
                    config={config}
                    facet={facet}
                    resultsText={resultsText}
                />
            );

        default:
            return (
                <DefaultFilterView
                    facet={facet}
                    resultsText={resultsText}
                    config={config}
                />
            );
    }
}
