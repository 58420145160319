'use client';

import { List } from '@costcolabs/forge-components';
import {
    // ColorTextDefault,
    FontSize300,
    SpaceMd,
} from '@costcolabs/forge-design-tokens';
import { Box, ListItem, styled } from '@mui/material';

export const SearchResultsFacetsContainer = styled(Box)({
    width: '100%',

    '.MuiAccordionSummary-root:hover': {
        backgroundColor: 'transparent !important',
    },
    '.MuiAccordionSummary-gutters': {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
    },

    '.MuiAccordion-region > div': {
        padding: '0 0 10px 0',
    },

    '.MuiAccordionSummary-content .MuiTypography-root': {
        fontSize: '1.25rem',
        fontWeight: '200',
    },
    '&  button': {
        fontSize: '1rem',
    },
    '& .MuiFormControlLabel-label div': {
        fontSize: '1rem',
    },
    // Delivery Location Selector header
    '.MuiTypography-t7': {
        fontSize: '1rem',
        fontWeight: 300,
    },
});

export const StyledListItem = styled(ListItem)({
    display: 'flex',
    alignItems: 'flext-start',
    justifyContent: 'space-between',
    padding: `0 0 ${SpaceMd} 0`,
    fontSize: FontSize300,
    // Override local checkbox label styles
    '& span div': {
        fontSize: FontSize300,
    },
});

export const FilterResultsTitleWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '@media (max-width: 767px)': {
        display: 'none',
    },
});

export const StyledFilterResultsTitle = styled('h2')({
    fontWeight: 400,
    margin: '10px 0 5px 0',
});

export const StyledList = styled(List)({
    margin: 0,
    padding: 0,
    '& .MuiFormControlLabel-root': {
        marginLeft: 0,
    },
});

export const StyledRowContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    '& div': {
        fontSize: '1rem',
    },
});

export const StyledFieldset = styled('fieldset')({
    margin: 0,
    padding: 0,
    border: 'none',
});
