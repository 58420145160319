import { useMediaQuery, useTheme } from '@mui/material';

const useCheckScreen = () => {
    const theme = useTheme();

    return {
        isDesktop: useMediaQuery(theme.breakpoints.up('lg')),
        isMobile: useMediaQuery(theme.breakpoints.between('xs', 'md')),
        isTablet: useMediaQuery(theme.breakpoints.between('md', 'lg')),
    };
};

export default useCheckScreen;
