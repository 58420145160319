'use client';

import { Box, styled } from '@mui/material';

export const SearchContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    gap: '40px',
    '& .MuiPagination-ul': {
        justifyContent: 'center',
    },
    '& p': {
        margin: 0,
    },
});
