'use client';

import { Placement } from '../AnalyticsObserver/types';

import { product_display_style } from '@/src/containers/search/constants';
import { LocaleProps } from '@/types/contentStack';
import {
    CriteoVideoSpotlight,
    useCheckScreen,
    useCriteo,
} from '@costcolabs/forge-digital-components';

export const VideoSpotlightClient = ({
    lang,
    position,
}: {
    lang: LocaleProps;
    position: string;
}) => {
    const { isMobile } = useCheckScreen();
    const { searchPageData } = useCriteo();

    // do not display this component above search results when the device is desktop/tablet
    if (position === 'filters' && isMobile) {
        return null;
    }

    if (position === 'grid' && !isMobile) {
        return null;
    }

    const videoSpotlightPlacementKey = `viewSearchResults_API_${isMobile ? 'mobile' : 'desktop'}-Video`;

    const videoSpotlightPlacements = searchPageData?.placements?.find(
        (placement: Record<string, Placement[]>) =>
            placement[videoSpotlightPlacementKey]
    );

    const videoSpotlightPlacement =
        videoSpotlightPlacements?.[`${videoSpotlightPlacementKey}`]?.[0];

    const ParentSKUs =
        videoSpotlightPlacement?.products.map(
            ({ ParentSKU }: { ParentSKU: string }) => ParentSKU
        ) || [];

    if (!videoSpotlightPlacement) {
        return null;
    } else {
        return (
            <CriteoVideoSpotlight
                contentStackEntry={{
                    criteo_placement_name: `Video`,
                    product_display_style,
                }}
                locale={lang}
                products={videoSpotlightPlacement?.products}
                placement={videoSpotlightPlacement}
                ParentSKUs={ParentSKUs}
                variant={'rail'}
                productTileSx={{
                    minHeight: '100%',
                    paddingRight: `2px`,
                }}
            />
        );
    }
};
