'use client';

import { useEffect, useState } from 'react';

import {
    getSelectFacetsFromUrl,
    updateRefinements,
} from '@/components/SearchResultsFacets/utils';
import { getKeyValue } from '@/services/content/business.lib';
import {
    FILTER_REMOVE,
    FILTER_VAL,
    TRACK_TYPE_MULTI_ATTR,
} from '@/src/components/Analytics/constants';
import { useLucidWorksContext } from '@/src/services/search/LucidWorksProvider';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import { Button } from '@costcolabs/forge-components';
import {
    FontLineHeightMd,
    FontSize200,
    FontWeightDefault,
    SpaceMd,
    SpaceXs,
    SpaceXxs,
} from '@costcolabs/forge-design-tokens';
import {
    Analytics,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { XThin } from '@costcolabs/forge-icons';
import { Box, styled } from '@mui/material';

const StyledButton = styled(Button)({
    padding: `${SpaceXxs} ${SpaceXs}`,
    fontSize: FontSize200,
    border: '1px solid #ccc',
    color: '#0060a9',
    fontWeight: FontWeightDefault,
    lineHeight: FontLineHeightMd,
    backgroundColor: '#fafafa',
    height: '32px',
    '&:hover': {
        backgroundColor: '#d9d9d9',
    },
    '&:focus': {
        backgroundColor: '#d9d9d9',
    },
});
export function SelectedFilters({
    config,
}: {
    config: ContentStackEntryDataProps;
}) {
    const { searchResult } = useLucidWorksContext();
    const [selectedFacets, setSelectedFacets] = useState<
        Array<{ label: string; filterType: string; refinement: string }>
    >([]);
    const { queryParams: searchParams, setQueryParams } = useQueryParams();

    const handleDelete = (refinement: string) => {
        updateRefinements(setQueryParams, refinement, false, searchParams);
    };

    useEffect(() => {
        if (!searchResult) {
            return undefined;
        }
        setSelectedFacets(
            getSelectFacetsFromUrl(searchParams, config, searchResult.facets)
        );
    }, [searchParams, config, searchResult]);
    const removeFilterText = getKeyValue('a11y.removefiltertext', config);
    return (
        <Box
            sx={{
                display: 'flex',
                margin: `${SpaceMd} 0 0`,
                flexWrap: 'wrap',
                gap: `${SpaceMd} ${SpaceMd}`,
            }}
        >
            {selectedFacets.map(({ label, filterType, refinement }, index) => {
                return (
                    <Analytics
                        analyticData={{
                            trackedData: [
                                {
                                    key: FILTER_REMOVE,
                                    value: filterType!,
                                },
                                {
                                    key: FILTER_VAL,
                                    value: label!,
                                },
                            ],
                            trackType: TRACK_TYPE_MULTI_ATTR,
                        }}
                        key={refinement + '-analytics'}
                    >
                        <StyledButton
                            key={`${refinement}-${index}-analytics`}
                            onClick={() => handleDelete(refinement!)}
                            aria-label={`${label}, ${removeFilterText}`}
                        >
                            {label}
                            <XThin
                                style={{
                                    height: '14px',
                                    paddingLeft: `${SpaceXs}`,
                                }}
                            />
                        </StyledButton>
                    </Analytics>
                );
            })}
        </Box>
    );
}
