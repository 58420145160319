export const ContentType = {
    AdBuilder: 'ad_builder',
    AdSet3rdParty: 'ad_set_3rd_party',
    AdSetCostco: 'ad_set_costco',
    AnnouncementBanner: 'announcement_banner',
    Category: 'category',
    CategoryLandingPage: 'category_landing_page',
    ConfigurationSetting: 'Configuration_Setting',
    CustomMarkdown: 'custom_markdown',
    FeatureHighlightCard: 'feature_highlight_card',
    FeatureToggle: 'Feature_Toggle',
    Footer: 'footer_scaffold',
    Header: 'header_scaffold',
    HomePage: 'homepage',
    LinkListSimple: 'link_list_simple',
    LinkListWithFlyout: 'link_list_with_flyout',
    ModuleSettings: '_module_settings',
    PageSettings: '_page_settings',
    ServiceConfiguration: 'Service_Configuration',
    SiteSettings: '_site_settings',
    SystemDefinedLink: 'system_defined_link',
    SearchKeywords: '_search_keyword', // Deprecated, don't use
    SearchPage: 'search_results_page',
    SearchRule: 'search_rule',
    ButtonSet: 'button_set',
} as const;
