'use client';

import { useEffect, useState } from 'react';

import { NO_RESULTS_CONTAINER_ID } from '@/src/app/[lang]/(customers)/s/constants';
import { SkipLink } from '@costcolabs/forge-components';

export const SkipToResultsLink = ({
    skipLinkText,
    targetId,
}: {
    skipLinkText: string;
    targetId: string;
}) => {
    const [showSkipLink, setShowSkipLink] = useState(true);

    useEffect(() => {
        const checkForProductListElement = () => {
            const noResultsContainerElement = document.getElementById(
                NO_RESULTS_CONTAINER_ID
            );
            setShowSkipLink(!noResultsContainerElement);
        };

        const observer = new MutationObserver(checkForProductListElement);

        const observeTarget = document.body.querySelector('main');
        observeTarget &&
            observer.observe(observeTarget, {
                childList: true,
                subtree: true,
            });

        checkForProductListElement();

        return () => observer.disconnect();
    }, []);

    return (
        <>
            {showSkipLink && (
                <SkipLink skipLinkText={skipLinkText} targetId={targetId} />
            )}
        </>
    );
};
