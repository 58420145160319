'use client';

import { A11Y_FILTER_DESCRIBED_BY } from '../../SearchResultsFacets/constants';

import {
    StyledList,
    StyledListItem,
} from '@/components/SearchResultsFacets/styles';
import {
    checkRefinementApplied,
    updateRefinements,
} from '@/components/SearchResultsFacets/utils';
import { OUT_OF_STOCK_TEXT_KEY } from '@/constants/index';
import { getKeyValue } from '@/services/content/business.lib';
import {
    FILTER,
    FILTER_REMOVE,
    FILTER_TYPE_LOCATION,
    FILTER_VAL,
    TRACK_TYPE_MULTI_ATTR,
} from '@/src/components/Analytics/constants';
import type { Facet } from '@/src/types/searchQuery';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import { Checkbox } from '@costcolabs/forge-components';
import {
    Analytics,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export function LocationFilterView({
    facet,
    resultsText,
    config,
}: {
    facet: Facet;
    resultsText: string;
    config: ContentStackEntryDataProps;
}) {
    const { queryParams: searchParams, setQueryParams } = useQueryParams();

    const handleDoRefine = (
        key: string,
        value: string | boolean,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        updateRefinements(
            setQueryParams,
            `${key}-${value}`,
            e.target.checked,
            searchParams
        );
    };
    return (
        // @ts-ignore TODO wtf?
        <StyledList sx={{ marginTop: '15px' }}>
            {facet.buckets.map(bucket => {
                const isChecked = checkRefinementApplied(
                    `${facet.facetKey}-${bucket.val}`,
                    searchParams
                );
                const label = getKeyValue(OUT_OF_STOCK_TEXT_KEY, config);
                return (
                    <StyledListItem
                        key={bucket.val.toString()}
                        data-private={bucket.leads_to_fsa_chdi}
                        data-bi-private={bucket.leads_to_fsa_chdi}
                    >
                        <Box>
                            <Analytics
                                analyticData={{
                                    trackedData: [
                                        {
                                            key: isChecked
                                                ? FILTER_REMOVE
                                                : FILTER,
                                            value: FILTER_TYPE_LOCATION,
                                        },
                                        {
                                            key: FILTER_VAL,
                                            value: label,
                                        },
                                    ],
                                    trackType: TRACK_TYPE_MULTI_ATTR,
                                    doNotTrack: bucket.leads_to_fsa_chdi,
                                }}
                            >
                                <Checkbox
                                    ariaDescribeText={A11Y_FILTER_DESCRIBED_BY}
                                    onChange={event =>
                                        handleDoRefine(
                                            facet.facetKey,
                                            bucket.val,
                                            event
                                        )
                                    }
                                    label={
                                        (
                                            <>
                                                {label}{' '}
                                                <Box sx={visuallyHidden}>
                                                    {`${bucket.count.toLocaleString()} ${resultsText}`}
                                                </Box>
                                            </>
                                        ) as unknown as string
                                    }
                                    isChecked={!!isChecked}
                                    id={`${facet.facetKey}-${bucket.val.toString().split(' ').join('+')}`}
                                />
                            </Analytics>
                        </Box>
                    </StyledListItem>
                );
            })}
        </StyledList>
    );
}
