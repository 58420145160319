'use client';

import { Divider, Skeleton } from '@costcolabs/forge-components';
import { Box } from '@mui/material';

export function FilterLoadingView() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
            }}
        >
            {[...Array(3).keys()].map((_, index) => {
                return (
                    <Box
                        key={`facet_loading_${index}`}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4,
                        }}
                    >
                        <Divider />
                        {/* @ts-ignore TODO: export this or let text as variant */}
                        <Skeleton variant="textHeader" />
                        {/* @ts-ignore TODO: export this or let text as variant */}
                        <Skeleton variant="textBody" />
                        {/* @ts-ignore TODO: export this or let text as variant */}
                        <Skeleton variant="textBody" />
                        {/* @ts-ignore TODO: export this or let text as variant */}
                        <Skeleton variant="textBody" />
                    </Box>
                );
            })}
        </Box>
    );
}
