'use client';

import { useState } from 'react';

import { Button, Modal } from '@costcolabs/forge-components';
import { FontSize300 } from '@costcolabs/forge-design-tokens';
import { useQueryParams } from '@costcolabs/forge-digital-components';

import { FilterPopupContainer } from './styles';

export function FilterPopup({
    children,
    filterLabel,
    clearAllLabel,
    seeResultsLabel,
}: {
    children: any;
    filterLabel: string;
    clearAllLabel: string;
    seeResultsLabel: string;
}) {
    const { queryParams: searchParamsMap } = useQueryParams();
    const searchParams = Object.fromEntries(searchParamsMap.entries());
    const [isOpen, setIsOpen] = useState(false);
    return (
        <FilterPopupContainer>
            <Button
                onClick={() => {
                    setIsOpen(true);
                }}
                variant="text-button"
                sx={{
                    minWidth: '0',
                    fontSize: FontSize300,
                }}
            >
                {filterLabel}
            </Button>
            <Modal
                buttons={[
                    { text: seeResultsLabel, action: () => setIsOpen(false) },
                    {
                        text: clearAllLabel,
                        href: `/s?keyword=${searchParams.keyword}`,
                    },
                ]}
                isOpen={isOpen}
                modalTitle={filterLabel}
                onClose={() => setIsOpen(false)}
                sx={{
                    '& > div > div > div:first-child': {
                        background: '#3071a9',
                        fontSize: '1.5em',
                        '& h2': {
                            color: '#fff',
                        },
                        '& button svg': {
                            color: '#fff',
                        },
                    },
                }}
            >
                {children}
            </Modal>
        </FilterPopupContainer>
    );
}
