'use client';

import { ReactNode } from 'react';

import { FilterGroup } from '@/components/FilterGroup';
import { ProgramEligibilityFilterGroup } from '@/components/FilterGroup/ProgramEligibility';
import { FacetsAccordion } from '@/components/FilterGroup/views';
import { ITEM_PROGRAM_FACET_KEY } from '@/constants/index';
import { ResultsAnnouncer } from '@/src/containers/search/ResultsAnnouncer';
import { getKeyValue } from '@/src/services/content/business.lib';
import { useLucidWorksContext } from '@/src/services/search/LucidWorksProvider';
import {
    ContentStackEntryDataProps,
    LocaleProps,
} from '@/src/types/contentStack';
import type { Facet } from '@/src/types/searchQuery';
import { PageType } from '@/types/pages/search';
import { Divider } from '@costcolabs/forge-components';
import { Box } from '@mui/material';

import {
    A11Y_FILTER_DESCRIBED_BY,
    A11Y_FILTER_KEY,
    ITEM_LOCATION_BOPIW,
    ITEM_LOCATION_FACET_KEY,
} from './constants';
import { FilterLoadingView } from './loading.view';
import { remapFacetDisplayOrder, sortFacets } from './utils';

/**
 * WWarehouseSelectorComponent & DeliveryLocationSelectorComponent must be rendered
 * server side, so they need to be passed in as props to this component.
 */
export default function SearchResultsFacetsClient({
    facetConfig,
    WarehouseSelectorComponent,
    DeliveryLocationSelectorComponent,
    locale,
    pageType,
}: {
    facetConfig: ContentStackEntryDataProps;
    WarehouseSelectorComponent: ReactNode;
    DeliveryLocationSelectorComponent: ReactNode;
    locale: LocaleProps;
    pageType: PageType;
}) {
    const { searchResult, isSearchResultsLoading } = useLucidWorksContext();

    if (isSearchResultsLoading) {
        return <FilterLoadingView />;
    }

    if (!searchResult) {
        return null;
    }

    return (
        <>
            <Box id={A11Y_FILTER_DESCRIBED_BY} sx={{ display: 'none' }}>
                {getKeyValue(A11Y_FILTER_KEY, facetConfig)}
            </Box>
            <ResultsAnnouncer
                announcerText={getKeyValue('a11y.newresults', facetConfig)}
            />
            {searchResult.facets
                .map(remapFacetDisplayOrder)
                .sort(sortFacets)
                .map((facet: Facet) => {
                    switch (facet.facetKey.toLowerCase()) {
                        // Hide Unavailable Items
                        case ITEM_LOCATION_FACET_KEY:
                            return (
                                <Box key={facet.facetKey}>
                                    <Divider />
                                    <FilterGroup
                                        facet={facet}
                                        config={facetConfig}
                                        locale={locale}
                                        pageType={pageType}
                                    />
                                </Box>
                            );
                        // Program Eligibility
                        case ITEM_PROGRAM_FACET_KEY:
                            return (
                                <ProgramEligibilityFilterGroup
                                    key={facet.facetKey}
                                    facet={facet}
                                    config={facetConfig}
                                    WarehouseSelectorComponent={
                                        WarehouseSelectorComponent
                                    }
                                    DeliveryLocationSelectorComponent={
                                        DeliveryLocationSelectorComponent
                                    }
                                    locale={locale}
                                    pageType={pageType}
                                />
                            );

                        case ITEM_LOCATION_BOPIW:
                            return (
                                <FilterGroup
                                    key={facet.facetKey}
                                    facet={facet}
                                    config={facetConfig}
                                    locale={locale}
                                    pageType={pageType}
                                />
                            );

                        default:
                            if (facet.buckets.length > 0) {
                                return (
                                    <FacetsAccordion
                                        key={facet.facetKey}
                                        facet={facet}
                                        facetConfig={facetConfig}
                                        locale={locale}
                                        pageType={pageType}
                                        breadcrumb={searchResult.breadcrumb}
                                    />
                                );
                            }
                            return null;
                    }
                })}
        </>
    );
}
