'use client';

import { A11Y_FILTER_DESCRIBED_BY } from '../../SearchResultsFacets/constants';

import { StyledList } from '@/components/SearchResultsFacets/styles';
import {
    checkRefinementApplied,
    updateRefinements,
} from '@/components/SearchResultsFacets/utils';
import { ITEM_LOCATION_BOPIW } from '@/constants/index';
import { PICK_UP_TEXT_KEY } from '@/constants/index';
import { getKeyValue } from '@/services/content/business.lib';
import {
    FILTER,
    FILTER_PICKUP,
    FILTER_REMOVE,
    FILTER_TYPE_LOCATION,
    FILTER_VAL,
    SHOP_BY_PREFIX,
    TRACK_TYPE_MULTI_ATTR,
} from '@/src/components/Analytics/constants';
import type { Facet } from '@/src/types/searchQuery';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import { Checkbox } from '@costcolabs/forge-components';
import {
    Analytics,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export function DefaultFilterView({
    facet,
    config,
    resultsText,
}: {
    facet: Facet;
    config: ContentStackEntryDataProps;
    resultsText: string;
}) {
    const { queryParams: searchParams, setQueryParams } = useQueryParams();

    const handleDoRefine = (
        key: string,
        value: string | boolean,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        updateRefinements(
            setQueryParams,
            `${key}-${value}`,
            e.target.checked,
            searchParams
        );
    };
    const showMoreText = getKeyValue('facet.showmoretext', config);
    return (
        // @ts-ignore TODO wtf?
        <StyledList
            variant="ExpandableList"
            showMoreText={showMoreText}
            ariaLabeledById={facet.facetKey}
            buttonId={`show_more_${facet.label.toLowerCase().split(' ').join('_')}`}
        >
            {facet.buckets
                .filter(bucket => {
                    return !(
                        facet.facetKey === ITEM_LOCATION_BOPIW &&
                        bucket.val === false
                    );
                })
                .map(bucket => {
                    let label = bucket.val;
                    // BOPIW comes in as { val: true, count: <count> }
                    if (
                        facet.facetKey === ITEM_LOCATION_BOPIW &&
                        bucket.val === true
                    ) {
                        label = getKeyValue(PICK_UP_TEXT_KEY, config)!;
                    }
                    const isChecked = checkRefinementApplied(
                        `${facet.facetKey}-${bucket.val}`,
                        searchParams
                    );
                    return (
                        <Box
                            key={bucket.val.toString()}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                            data-private={bucket.leads_to_fsa_chdi}
                            data-bi-private={bucket.leads_to_fsa_chdi}
                        >
                            <Analytics
                                analyticData={{
                                    trackedData: [
                                        {
                                            key: isChecked
                                                ? FILTER_REMOVE
                                                : FILTER,
                                            value:
                                                facet.label === FILTER_PICKUP
                                                    ? FILTER_TYPE_LOCATION
                                                    : SHOP_BY_PREFIX +
                                                      facet.label,
                                        },
                                        {
                                            key: FILTER_VAL,
                                            value: label.toString(),
                                        },
                                    ],
                                    trackType: TRACK_TYPE_MULTI_ATTR,
                                    doNotTrack: bucket.leads_to_fsa_chdi,
                                }}
                            >
                                <Checkbox
                                    ariaDescribeText={A11Y_FILTER_DESCRIBED_BY}
                                    onChange={event =>
                                        handleDoRefine(
                                            facet.facetKey,
                                            bucket.val,
                                            event
                                        )
                                    }
                                    isChecked={!!isChecked}
                                    label={
                                        (
                                            <>
                                                {label}{' '}
                                                <Box sx={visuallyHidden}>
                                                    {`${bucket.count.toLocaleString()} ${resultsText}`}
                                                </Box>
                                            </>
                                        ) as unknown as string
                                    }
                                    id={`${facet.facetKey}-${bucket.val}`}
                                />
                            </Analytics>
                            <Box aria-hidden>
                                ({bucket.count.toLocaleString()})
                            </Box>
                        </Box>
                    );
                })}
        </StyledList>
    );
}
