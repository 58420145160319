'use client';

import { ReactNode } from 'react';

import { fullyDecodeURI } from '@/src/components/SearchResultsFacets/utils';
import useQueryParams from '@/src/hooks/useQueryParams';
import { useLucidWorksContext } from '@/src/services/search/LucidWorksProvider';
import {
    ContentStackEntryDataProps,
    LocaleProps,
} from '@/src/types/contentStack';
import { CriteoProviderClientComponent } from '@costcolabs/forge-digital-components';

import { createCriteoSearchFiltersString } from './utils';

export default function CriteoProviderWrapper({
    productTileConfigData,
    criteoAPIConfigurationSettings,
    lang,
    children,
}: {
    productTileConfigData: ContentStackEntryDataProps;
    criteoAPIConfigurationSettings: ContentStackEntryDataProps;
    lang: LocaleProps;
    children: ReactNode;
}) {
    const { searchResult, searchedParams, isSearchResultsLoading } =
        useLucidWorksContext();

    let refine = searchedParams?.refine;
    if (Array.isArray(refine)) {
        refine = refine[0];
    }

    return (
        <CriteoProviderClientComponent
            productTileConfigData={productTileConfigData}
            criteoAPIConfigurationSettings={criteoAPIConfigurationSettings}
            locale={lang}
            searchResult={searchResult}
            searchParams={{
                ...(searchedParams || {}),
                refine: createCriteoSearchFiltersString(fullyDecodeURI(refine)),
            }}
            isSearchResultsLoading={isSearchResultsLoading}
            listSize={24}
            isTargetingDisabled={!searchResult?.isAdTargetingEnabled}
        >
            {children}
        </CriteoProviderClientComponent>
    );
}
