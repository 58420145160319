'use client';

import { useEffect, useState } from 'react';

import { SEARCH_RESULT_CONTAINER_ID } from '@/constants/index';
import SearchPageDigitalDataLayer from '@/src/components/Analytics/SearchPageDigitalDataLayer';
import { useLucidWorksContext } from '@/src/services/search/LucidWorksProvider';
import { LocaleProps } from '@/src/types/contentStack';
import { LocaleType } from '@/src/types/pages/search';
import { modernizeCategoryLink } from '@/utils/links';
import { Breadcrumbs } from '@costcolabs/forge-components';
import { Box } from '@mui/material';

import { SkipToResultsLink } from './SkipToResultsLink';

import { BreadCrumbContainerStyle } from './styles';

export function CategoryBreadCrumbClient({
    lang,
    arialLabel,
    skipLinkText,
    initialValues = [],
}: {
    lang: LocaleProps;
    arialLabel: string;
    skipLinkText: string;
    initialValues?: Array<{ name: string; link: string }>;
}) {
    const { searchResult, isSearchResultsLoading } = useLucidWorksContext();
    const [breadcrumbs, setBreadcrumbs] =
        useState<Array<{ name: string; link: string }>>(initialValues);

    useEffect(() => {
        if (!searchResult || !searchResult.breadcrumb) {
            return;
        }

        setBreadcrumbs([
            { name: 'Home', link: '/' },
            ...searchResult.breadcrumb.map(breadCrumb => ({
                name: breadCrumb.name,
                link: modernizeCategoryLink(breadCrumb.url),
            })),
        ]);
    }, [searchResult]);

    useEffect(() => {
        if (document) {
            // Set the page title to the last breadcrumb
            if (breadcrumbs?.[breadcrumbs.length - 1]?.name) {
                document.title = `${breadcrumbs[breadcrumbs.length - 1]!.name} | Costco`;
            }
        }
    }, [breadcrumbs]);

    if (isSearchResultsLoading || !breadcrumbs) {
        return null;
    }

    return (
        <Box sx={BreadCrumbContainerStyle}>
            <SearchPageDigitalDataLayer
                pageCrumbs={breadcrumbs
                    .filter((_breadCrumb, index) => index > 0)
                    .map(breadCrumb => breadCrumb.name)}
                lang={lang as LocaleType}
                pageName="Search"
                site={process.env.SITE}
            />
            <Breadcrumbs
                ariaLabel={arialLabel}
                separator="/"
                breadcrumbElements={breadcrumbs}
            />
            <SkipToResultsLink
                skipLinkText={skipLinkText}
                targetId={SEARCH_RESULT_CONTAINER_ID}
            />
        </Box>
    );
}
