'use client';

import { A11Y_FILTER_DESCRIBED_BY } from '../../SearchResultsFacets/constants';

import { CostcoGroceryLabel } from '@/components/CostcoGroceryLabel';
import {
    StyledList,
    StyledListItem,
} from '@/components/SearchResultsFacets/styles';
import {
    checkRefinementApplied,
    getFacetLabel,
    updateRefinements,
} from '@/components/SearchResultsFacets/utils';
import {
    FILTER,
    FILTER_REMOVE,
    FILTER_TYPE_LOCATION,
    FILTER_VAL,
    TRACK_TYPE_MULTI_ATTR,
} from '@/src/components/Analytics/constants';
import type { Facet } from '@/src/types/searchQuery';
import type {
    ContentStackEntryDataProps,
    LocaleProps,
} from '@/types/contentStack';
import { Checkbox } from '@costcolabs/forge-components';
import { ColorCostcoBlue } from '@costcolabs/forge-design-tokens';
import {
    Analytics,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export function ProgramFilterView({
    facet,
    config,
    resultsText,
    locale,
}: {
    facet: Facet;
    config: ContentStackEntryDataProps;
    resultsText: string;
    locale: LocaleProps;
}) {
    const { queryParams: searchParams, setQueryParams } = useQueryParams();

    const handleDoRefine = (
        key: string,
        value: string | boolean,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        updateRefinements(
            setQueryParams,
            `${key}-${value}`,
            e.target.checked,
            searchParams
        );
    };

    return (
        // @ts-ignore TODO wtf?
        <StyledList>
            {facet.buckets.map(bucket => {
                let label = getFacetLabel(bucket.val.toString(), config);
                let filterName = label || undefined;
                if (label === null) {
                    return null;
                }

                if (bucket.val.toString().toLowerCase() === '2daydelivery') {
                    // 2-day delivery in Canada is CostcoGrocery and has special labeling
                    if (
                        label === 'CostcoGrocery' ||
                        label === 'Épicerie Costco'
                    ) {
                        label = (
                            <CostcoGroceryLabel
                                locale={locale}
                                config={config}
                            />
                        ) as unknown as string;
                    } else {
                        label = (
                            <Box
                                sx={{ fontWeight: 800, color: ColorCostcoBlue }}
                            >
                                {label}
                            </Box>
                        ) as unknown as string;
                    }
                }
                const isChecked = checkRefinementApplied(
                    `${facet.facetKey}-${bucket.val}`,
                    searchParams
                );
                return (
                    <StyledListItem
                        key={bucket.val.toString()}
                        data-private={bucket.leads_to_fsa_chdi}
                        data-bi-private={bucket.leads_to_fsa_chdi}
                    >
                        <Box>
                            <Analytics
                                analyticData={{
                                    trackedData: [
                                        {
                                            key: isChecked
                                                ? FILTER_REMOVE
                                                : FILTER,
                                            value: FILTER_TYPE_LOCATION,
                                        },
                                        {
                                            key: FILTER_VAL,
                                            value: filterName ? filterName : '',
                                        },
                                    ],
                                    trackType: TRACK_TYPE_MULTI_ATTR,
                                    doNotTrack: bucket.leads_to_fsa_chdi,
                                }}
                            >
                                <Checkbox
                                    ariaDescribeText={A11Y_FILTER_DESCRIBED_BY}
                                    onChange={event =>
                                        handleDoRefine(
                                            facet.facetKey,
                                            bucket.val,
                                            event
                                        )
                                    }
                                    label={
                                        (
                                            <>
                                                {label}{' '}
                                                <Box sx={visuallyHidden}>
                                                    {`${bucket.count.toLocaleString()} ${resultsText}`}
                                                </Box>
                                            </>
                                        ) as unknown as string
                                    }
                                    isChecked={!!isChecked}
                                    id={`${facet.facetKey}-${bucket.val}`}
                                />
                            </Analytics>
                        </Box>
                        <Box aria-hidden>({bucket.count.toLocaleString()})</Box>
                    </StyledListItem>
                );
            })}
        </StyledList>
    );
}
