'use client';

import Script from 'next/script';

import useCheckScreen from '@/src/hooks/useCheckScreen';
import {
    useBrowseContext,
    useQueryParams,
} from '@costcolabs/forge-digital-components';

const OneTagSearchPageEventScriptClient = ({
    partnerId,
}: {
    partnerId: string;
}) => {
    const { criteoUserCookie } = useBrowseContext();
    const { isMobile } = useCheckScreen();
    const { queryParams: searchParams } = useQueryParams();
    const keywordParam = searchParams.get('keyword');
    const currentPageParam = searchParams.get('currentPage');
    const refineParam = searchParams.get('refine');

    if (!criteoUserCookie) {
        return null;
    }

    return (
        <Script id="CriteoOneTagEventsScript" strategy="lazyOnload">
            {`
        if(typeof window !== "undefined") {
          window.criteo_q = window.criteo_q || [];

          const deviceType = ${isMobile} ? "m" : "d";
          const pageId = ${isMobile} ? "viewSearchOffsite_mobile" : "viewSearchOffsite_desktop";
        
          const criteoEventsArray = [
            { event: "setAccount", account: "${partnerId}"},
            { event: "setEmail", email: "${criteoUserCookie}", hash_method: "sha256" },
            { event: "setSiteType", type: deviceType},
            { event: "setRetailerVisitorId", id: "${criteoUserCookie}"},
            { event: "viewSearchResult", 
              page_id: pageId, 
              keyword: ${keywordParam ? `"${keywordParam}"` : 'null'}, 
              filters: ${refineParam ? `"${refineParam}"` : 'null'},
              page_number: ${currentPageParam ? `"${currentPageParam}"` : 1} 
            }
          ];
          
          if(${process.env.CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION === 'prod'}) {
            criteoEventsArray.push({ event: "setUat", uat: "1"});
          }
          window.criteo_q.push(criteoEventsArray);
        };
      `}
        </Script>
    );
};

export default OneTagSearchPageEventScriptClient;
