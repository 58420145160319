'use client';

import { updatePageParam } from '../SearchResultsFacets/utils';

import { useTracking } from 'react-tracking';

import { BACK_TO_TOP_LABEL_KEY } from '@/src/containers/search/constants';
import { getKeyValue } from '@/src/services/content/business.lib';
import { useLucidWorksContext } from '@/src/services/search/LucidWorksProvider';
import { ContentStackEntryDataProps } from '@/src/types/contentStack';
import {
    BackToTopLink,
    Divider,
    Pagination,
    Skeleton,
} from '@costcolabs/forge-components';
import {
    Analytics,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { Box } from '@mui/system';

export default function SearchResultsPagination({
    config,
}: {
    config: ContentStackEntryDataProps;
}) {
    const { searchResult, isSearchResultsLoading } = useLucidWorksContext();
    const { trackEvent } = useTracking();
    const { queryParams: searchParams, setQueryParams } = useQueryParams();

    /**
     * Function to identify the selected button by checking its aria label
     * @param e
     * @param v
     * @returns
     */
    const getSelectedButton = (e: React.ChangeEvent<any>, v: number) => {
        return e?.currentTarget?.ariaLabel === 'Go to previous page'
            ? 'Previous Arrow'
            : e?.currentTarget?.ariaLabel === 'Go to next page'
              ? 'Next Arrow'
              : v.toString();
    };

    const handlePagination = (e: React.ChangeEvent<any>, v: number) => {
        trackEvent({
            component: {
                identifier: 'Search',
                trackedData: ['Pagination Control', getSelectedButton(e, v)],
                type: 'nav',
            },
            trackType: 'navigation',
        });

        updatePageParam(setQueryParams, searchParams, v.toString());
    };

    if (isSearchResultsLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: 2,
                }}
            >
                <Skeleton width={35} height={35} />
                <Skeleton width={35} height={35} />
                <Skeleton width={35} height={35} />
            </Box>
        );
    }

    if (!searchResult) {
        return null;
    }

    const { pagination } = searchResult;
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent:
                    searchResult.pagination?.totalPages > 1
                        ? 'space-between'
                        : 'flex-end',
                '@media (max-width: 767px)': {
                    flexDirection: 'column',
                },
                paddingTop: '10px',
            }}
        >
            {pagination.totalPages > 1 ? (
                <Pagination
                    count={pagination.totalPages}
                    handleChange={handlePagination}
                    page={
                        searchParams.has('currentPage')
                            ? parseInt(searchParams.get('currentPage')!)
                            : 1
                    }
                    defaultPage={1}
                    sx={{ flexGrow: 1 }}
                />
            ) : (
                <Box />
            )}
            <Divider
                sx={{
                    display: 'none',
                    '@media (max-width: 767px)': {
                        display: 'block',
                        marginTop: '10px',
                    },
                }}
            />
            <Box
                sx={{
                    textWrap: 'nowrap',
                    '@media (max-width: 767px)': {
                        alignSelf: 'end',
                        marginTop: '10px',
                    },
                }}
            >
                <Analytics
                    analyticData={{
                        component: {
                            identifier: 'Search',
                            trackedData: ['Back to Top'],
                            type: 'ui',
                        },
                        trackType: 'navigation',
                    }}
                >
                    <BackToTopLink
                        text={getKeyValue(BACK_TO_TOP_LABEL_KEY, config)}
                    />
                </Analytics>
            </Box>
        </Box>
    );
}
