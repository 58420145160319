'use client';

import { ReactNode, memo } from 'react';

import { Box, SxProps } from '@mui/material';

import { AnalyticObserverProps } from './types';
import { useAnalyticObserverRef } from './utils';

export const AnalyticsObserver = memo(
    (props: {
        sx?: SxProps;
        observer: AnalyticObserverProps;
        children: ReactNode;
    }) => {
        const observableRef = useAnalyticObserverRef(props.observer);

        return (
            <Box ref={observableRef} sx={props.sx}>
                {props.children}
            </Box>
        );
    }
);

AnalyticsObserver.displayName = 'AnalyticsObserver';
