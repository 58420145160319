import { useCallback, useEffect, useMemo, useRef } from 'react';

import {
    CRITEO_INTERSECTION_THRESHOLD,
    CRITEO_TIME_DELAY_IN_MS,
} from './constants';
import {
    AnalyticObserverProps,
    BeaconType,
    CriteoProduct,
    Placement,
} from './types';

export const instantiateObserver = (
    handleIntersect: IntersectionObserverCallback,
    options?: IntersectionObserverInit
): IntersectionObserver => {
    const observer = new IntersectionObserver(handleIntersect, options);
    return observer;
};

export const useAnalyticObserverRef = ({
    onClick,
    onLoad,
    onView,
    minimumViewTime = 1000,
    viewThreshold = 0.5,
}: AnalyticObserverProps) => {
    const observableRef = useRef<Element>();

    useEffect(() => {
        let timer: NodeJS.Timeout;

        const handleIntersect = (entries: IntersectionObserverEntry[]) => {
            const entry = entries[0]; //observer is created for each entry/card, so for every intersection there will be only single entry
            if (entry?.isIntersecting) {
                timer = setTimeout(() => {
                    if (onView) onView();
                    observer.unobserve(entry.target);
                }, minimumViewTime);
            } else {
                clearTimeout(timer);
            }
        };

        const observer = instantiateObserver(handleIntersect, {
            threshold: viewThreshold,
        });

        const current = observableRef?.current;

        if (current) {
            onLoad?.();
            observer.observe(current);
        }

        if (onClick) {
            current?.addEventListener('click', onClick);
        }

        return () => {
            observer.disconnect();
            clearTimeout(timer);
            if (onClick) current?.removeEventListener('click', onClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [observableRef, onLoad, onView]);

    return observableRef;
};

export function useCriteoAnalytics({
    placement,
    products,
}: {
    placement?: Placement;
    products?: CriteoProduct[];
}) {
    const sendBeacon = (beacon: string) => {
        if (beacon) navigator.sendBeacon(beacon);
    };

    const getProduct = useCallback(
        (itemNumber: string): CriteoProduct | undefined => {
            if (products)
                return products?.find(
                    ({ ParentSKU }) => ParentSKU === itemNumber
                );
        },
        [products]
    );

    const onTileAction = useCallback(
        (itemNumber: string, beaconType: BeaconType) => {
            const product = getProduct(itemNumber);

            if (product) sendBeacon(product?.[beaconType]);
        },
        [getProduct]
    );

    const onPlacementLoad = useCallback(() => {
        if (placement) sendBeacon(placement.OnLoadBeacon);
    }, [placement]);

    const onPlacementViewed = useCallback(() => {
        if (placement) sendBeacon(placement.OnViewBeacon);
    }, [placement]);

    const onBannerClicked = useCallback(() => {
        if (placement) sendBeacon(placement.OnClickBeacon);
    }, [placement]);

    const onBundleBasketChangeBeacon = useCallback(() => {
        if (placement) sendBeacon(placement.OnBundleBasketChangeBeacon);
    }, [placement]);

    const onBannerFileClicked = useCallback(() => {
        if (placement) sendBeacon(placement.OnFileClickBeacon);
    }, [placement]);

    const onTileLoad = useCallback(
        (itemNumber: string) => onTileAction(itemNumber, 'OnLoadBeacon'),
        [onTileAction]
    );

    const onTileViewed = useCallback(
        (itemNumber: string) => onTileAction(itemNumber, 'OnViewBeacon'),
        [onTileAction]
    );

    const onTileClicked = useCallback(
        (itemNumber: string) => onTileAction(itemNumber, 'OnClickBeacon'),
        [onTileAction]
    );

    return useMemo(
        () => ({
            minimumViewTime: CRITEO_TIME_DELAY_IN_MS,
            onBannerClicked,
            onBannerFileClicked,
            onBundleBasketChangeBeacon,
            onPlacementLoad,
            onPlacementViewed,
            onTileClicked,
            onTileLoad,
            onTileViewed,
            viewThreshold: CRITEO_INTERSECTION_THRESHOLD,
        }),
        [
            onBannerClicked,
            onBannerFileClicked,
            onBundleBasketChangeBeacon,
            onPlacementLoad,
            onPlacementViewed,
            onTileClicked,
            onTileLoad,
            onTileViewed,
        ]
    );
}
