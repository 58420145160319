'use client';

import { useEffect, useRef, useState } from 'react';

import { PHONE_MEDIA_QUERY } from '@/constants/styles';
import { getKeyValue } from '@/services/content/business.lib';
import { ContentStackEntryDataProps } from '@/types/contentStack';
import { Button } from '@costcolabs/forge-components';
import { ChevronDown, ChevronUp } from '@costcolabs/forge-icons';
import { Box, SxProps } from '@mui/material';

export function Wrapper({
    children,
    sx = {},
    buttonConfig,
}: {
    children: React.ReactNode;
    sx?: SxProps;
    buttonConfig: ContentStackEntryDataProps;
}) {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);
    const [showMore, setShowMore] = useState(false);
    const [expanded, setIsExpanded] = useState(false);

    function hide(
        wrapperRef: React.RefObject<HTMLDivElement>,
        innerRef: React.RefObject<HTMLDivElement>
    ) {
        if (!wrapperRef.current || !innerRef.current) {
            return;
        }

        let maxWidth = innerRef.current.clientWidth;

        Array.from(innerRef.current.children).forEach(child => {
            maxWidth -= child.clientWidth + 6;

            if (maxWidth < 0) {
                setShowMore(true);

                child.setAttribute('tabindex', '-1');
            }
        });
    }

    function show() {
        if (!wrapperRef.current || !innerRef.current) {
            return;
        }
        let firstFocused = false;
        Array.from(innerRef.current.children).forEach(child => {
            if (parseInt(child?.getAttribute('tabindex')!) < 0) {
                child.removeAttribute('tabindex');
                if (!firstFocused) {
                    // @ts-ignore
                    child.focus({ focusVisible: true });
                    firstFocused = true;
                }
            }
        });
    }

    useEffect(() => {
        if (wrapperRef.current && innerRef.current) {
            // If height of inner container is larger than the wrapper,
            // we need to display the "Show More" button
            expanded ? show() : hide(wrapperRef, innerRef);
        }
    }, [wrapperRef, innerRef, expanded]);

    return (
        <Box sx={sx}>
            <Box
                sx={{
                    height: expanded
                        ? `${innerRef.current!.scrollHeight + 4}px`
                        : '46px',
                    overflow: 'hidden',
                    padding: '2px',
                    [PHONE_MEDIA_QUERY]: {
                        overflow: 'auto',
                        '&::-webkit-scrollbar ': {
                            display: 'none',
                        },
                    },
                }}
                ref={wrapperRef}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'left',
                        gap: '6px',
                        [PHONE_MEDIA_QUERY]: {
                            flexWrap: 'nowrap',
                        },
                    }}
                    ref={innerRef}
                >
                    {children}
                </Box>
            </Box>
            {showMore && (
                <Button
                    variant="text-button"
                    onClick={() => {
                        setIsExpanded(!expanded);
                    }}
                    sx={{
                        fontSize: '16px',
                        width: '100px',
                        color: '#333',
                        [PHONE_MEDIA_QUERY]: {
                            display: 'none',
                        },
                    }}
                >
                    {expanded ? (
                        <>
                            {getKeyValue('seeless', buttonConfig)}
                            <ChevronUp
                                style={{ width: '20px', marginLeft: '4px' }}
                            />
                        </>
                    ) : (
                        <>
                            {getKeyValue('seemore', buttonConfig)}
                            <ChevronDown
                                style={{ width: '20px', marginLeft: '4px' }}
                            />
                        </>
                    )}
                </Button>
            )}
        </Box>
    );
}
