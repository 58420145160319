'use client';

import {
    FontLineHeightMd,
    FontWeightDefault,
} from '@costcolabs/forge-design-tokens';
import { styled } from '@mui/material';

export const Header = styled('h1')({
    fontSize: '2.25rem',
    marginBottom: '1.25rem',
    fontWeight: FontWeightDefault,
    lineHeight: FontLineHeightMd,
    marginTop: 0,
    '@media (max-width: 767px)': {
        fontSize: '26px',
    },
});
