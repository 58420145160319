'use client';

import { useEffect, useRef, useState } from 'react';

import { FilterGroup } from '@/components/FilterGroup';
import { StyledFieldset } from '@/components/SearchResultsFacets/styles';
import {
    ITEM_CATEGORY_FACET_KEY,
    ITEM_LOCATION_FACET_KEY,
    ITEM_PROGRAM_FACET_KEY,
} from '@/constants/index';
import { Breadcrumb, Facet } from '@/src/types/searchQuery';
import type {
    ContentStackEntryDataProps,
    LocaleProps,
} from '@/types/contentStack';
import { PageType } from '@/types/pages/search';
import { Accordion } from '@costcolabs/forge-components';

export function FacetsAccordion({
    facet,
    facetConfig,
    locale,
    pageType,
    breadcrumb,
}: {
    facet: Facet;
    facetConfig: ContentStackEntryDataProps;
    locale: LocaleProps;
    pageType: PageType;
    breadcrumb?: Breadcrumb;
}) {
    const [isExpanded, setIsExpanded] = useState(true);
    const accordionRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const accordionInnerContent = accordionRef.current?.querySelector(
            "div.MuiAccordion-region[role='region']"
        );

        if (accordionInnerContent) {
            accordionInnerContent.removeAttribute('role');
        }
    }, []);

    function renderHeading(facet: Facet) {
        if (facet.facetKey === ITEM_PROGRAM_FACET_KEY) {
            return '';
        }

        if (facet.facetKey === ITEM_LOCATION_FACET_KEY) {
            return '';
        }
        return facet.label;
    }

    return (
        <Accordion
            title={renderHeading(facet)}
            // @ts-ignore Accordion doesn't export this type
            variant="primary"
            isExpanded={isExpanded}
            onChange={() => setIsExpanded(!isExpanded)}
            uniqueId={facet.facetKey}
            ref={accordionRef}
        >
            {facet.facetKey === ITEM_CATEGORY_FACET_KEY ? (
                <FilterGroup
                    facet={facet}
                    config={facetConfig}
                    locale={locale}
                    pageType={pageType}
                    breadcrumb={breadcrumb}
                />
            ) : (
                <StyledFieldset aria-labelledby={facet.facetKey}>
                    <FilterGroup
                        facet={facet}
                        config={facetConfig}
                        locale={locale}
                        pageType={pageType}
                    />
                </StyledFieldset>
            )}
        </Accordion>
    );
}
